

















































































































































































































import { TopicVm } from "@/interfaces/TopicVm";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

//import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");
const notify = namespace("notify");

@Component({
  components: {
    //HelloWorld,
  },
})

export default class Home extends Vue {
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  @notify.Action loadTopicNotifications: any;
  @notify.Action topicNotificationsNoted: any;
  @notify.Action updateTopics: any;
  @notify.Getter hasNewTopicNotifications: any;
  @notify.Getter newTopicNotificationCount: any;
  @notify.Getter isPushSupportedByBrowser: any;

  busy = false;
  showDlgSubscribe = false;
  topics: TopicVm[] = [];
  selectedTopics: string[] = [];

  currentTime: string = new Date().toLocaleTimeString();
  currentDate: string = new Date().toISOString().substr(0, 10);
  myTimer: number = 0;

  async mounted() {
    await this.loadTopicNotifications();
    await this.loadTopics();
    // By using lambda syntax for interval function <this> will have the same scope as parent class Home
    // So we can use this.currentTime in interval function
    // Start timer in mount function and not in constructor to make binding work.
    this.myTimer = setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.myTimer);
  }

    async loadTopics() {
    // this.unsubscribedTopics = await rest.url('notification/getUnsubscribedTopics').get();
    this.topics = await rest.url('notification/getTopics').get();
    this.topics.forEach(t => {
      // t.selected = true;
      // if( this.unsubscribedTopics.some(u => u.id === t.id)) {
      //   t.selected = false;
      // }
      if (t.selected)
        this.selectedTopics.push(t.value!);
    });
  }

  get ListItemImgHeight() {
    if (this.$vuetify.breakpoint.xs)
      return 80;
    return 150;
  }

  get ListItemTextWidth() {
    let width = `calc(100% - ${this.ListItemImgHeight}px)`;
    // console.log("width: ", width);
    return width;
  }

  get HasNewTopicNotifications() {
    return this.hasNewTopicNotifications;
  }

  get NewTopicNotificationCount() {
    const count = this.newTopicNotificationCount;
    if (count > 99) {
      return '!';
    }
    return count;
  }

  get Notifications() {
    if (!this.$store.state.notify.topicNotifications)
      return [];

    if(this.selectedTopics.length > 0) {
      const list = this.$store.state.notify.topicNotifications.filter(n =>
          n.topics.some(p => this.selectedTopics.some(s => s === p)))
      return list;
    }

    return this.$store.state.notify.topicNotifications;
  }

  get NotificationIcon() {
    if (this.isPushSupportedByBrowser)
      return "mdi-bell-outline";
    return "mdi-bell-sleep"
  }

  async subscribeTopics() {
    this.busy = true;
    await this.updateTopics(this.topics)
    .then(() => {
      this.showDlgSubscribe = false;
    })
    .finally(() => {
      this.busy = false;
    });
    await this.loadTopicNotifications();
  }

  getDescription(topic: TopicVm) : string {
    let idx = topic.descriptions!.findIndex(d => d.item1 === "de");
    if(idx < 0) {
      idx = topic.descriptions!.findIndex(d => d.item1 === 'en');
    }
    if(idx < 0) {
      idx = 0;
    }
    return topic.descriptions![idx].item2;
  }

  get dlgBackgroundColor() {
      return this.$vuetify.theme.dark ? "#505050" : "#CCD0E8";
  }
}
