import { ActionTree, GetterTree, MutationTree } from "vuex";
import rest, { refreshJwt } from "@/rest";
import router from "../../router";

import { Module } from "vuex";
import { RootStore } from "../types";
import { User } from "../types/auth";
import jwt_decode from "jwt-decode";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

const state: User = {
  myName: "",
  loginTime: "",
  timeoutInMs: 5 * 60 * 1000, // 5 minutes -> 5 * 60 * 1000ms
  pollTimerId: 0,
  timeoutStart: new Date(),
  logoutTimeInSeconds: 300,
  jwtExpiration: 0,
  isLoggedIn: false,
  portalUser: null
};

const getters: GetterTree<User, RootStore> = {
  isAdmin: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Admin")) return true;
    return false;
  },
  isNotificationAdmin: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("NotificationAdmin")) return true;
    return false;
  },
  isContentManager: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("ContentManager")) return true;
    return false;
  },
  isPrincipal: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Principal")) return true;
    return false;
  },
  isTeacher: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Teacher")) return true;
    return false;
  },
  isParent: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Parent")) return true;
    return false;
  },
  isLearner: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Learner")) return true;
    return false;
  },
  isPupil: State => {
    if (State.portalUser == null || State.portalUser.roles == null) return false;
    if (State.portalUser.roles.includes("Pupil")) return true;
    return false;
  },
  loginName: State => {
    if (State.portalUser == null) return "Bitte anmelden";
    if (State.portalUser.nickname != null && State.portalUser.nickname.length > 0)
      return State.portalUser.nickname;
    if (State.portalUser.fullName != null && State.portalUser.fullName.length > 0)
      return State.portalUser.fullName;
    if (State.portalUser.email != null && State.portalUser.email.length > 0)
      return State.portalUser.email;

    return State.portalUser.userName;
  },
  loggedIn: State => {
    return State.isLoggedIn;
  },
  logoutTime: State => {
    return State.logoutTimeInSeconds;
  }
};

const actions: ActionTree<User, RootStore> = {
  async RegisterUser({ commit, dispatch }, credentials) {
    let obtainJwt = await rest.url("Auth/register_portaluser").post(credentials);
    if (!obtainJwt) return;

    window.localStorage.setItem(
      "digiClassAuth",
      // obtainJwt.token.split('"').join('')
      obtainJwt
    );
    await dispatch("Login");
  },
  async PasswordLogin({ commit, dispatch }, credentials) {
    let obtainJwt = await rest.url("Auth/jwtSignIn").post(credentials);
    if (!obtainJwt) return;
    window.localStorage.setItem(
      "digiClassAuth",
      obtainJwt
    );
    await dispatch("Login");
  },
  async Login({ commit, dispatch, getters }) {
    let loginJwt = refreshJwt();
    if (loginJwt == null)
      return;

    let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    if (portalUser == null) {
      window.localStorage.removeItem("digiClassAuth");
      commit("logout");
      // router.push("/");
      dispatch("goHome");
      return;
    }

    let payload = jwt_decode(loginJwt);
    await commit("login", { payload, portalUser });
    dispatch("setupIdleTimers");

    // start firebase service and register to topics
    // console.log("registerFcm");
    dispatch("notify/registerFcm", null, { root: true }); // Start async to speed up initialization

    // update notifications
    dispatch("notify/loadTopicNotifications", null, { root: true }); // Start async to speed up initialization
    dispatch("notify/loadUserNotifications", null, { root: true }); // Start async to speed up initialization

    // Set current class
    if (portalUser.schoolClasses != null && portalUser.schoolClasses.length > 0)
      await commit("globals/setSchoolClass", portalUser.schoolClasses[0], { root: true }); // Set root to true to be able to access this action of globals

    // Set selectedPupil if role is pupil
    // TODO: Set pupil also if role is parent
    if (getters.isPupil)
      await commit("globals/setPupil", portalUser, { root: true });

    //dispatch("mailAccount/obtainMailAccounts", null, { root: true })
  },
  async Logout({ commit, dispatch }) {
    window.localStorage.removeItem("digiClassAuth");
    commit("logout");
    // router.push("/");
    dispatch("goHome");
  },
  goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  },
  setupIdleTimers({ state, getters, commit, dispatch }) {
    console.log("Start idle timer...");
    document.addEventListener("keypress", () => dispatch("resetLogoutTimer"), false);
    document.addEventListener("mousemove", () => dispatch("resetLogoutTimer"), false);
    document.addEventListener("mousedown", () => dispatch("resetLogoutTimer"), false);
    document.addEventListener("touchmove", () => dispatch("resetLogoutTimer"), false);
    document.addEventListener("scroll", () => dispatch("resetLogoutTimer"), false);

    if (getters.isAdmin || getters.isContentManager || getters.isNotificationAdmin)
      state.timeoutInMs = 90 * 60 * 1000;// 90 minutes -> 90 * 60 * 1000ms
    else
      state.timeoutInMs = 5 * 60 * 1000; // 5 minutes -> 5 * 60 * 1000ms

    dispatch("startPollTimer");
  },
  resetLogoutTimer({ state, commit, dispatch }) {
    commit("setTimeoutStart");
  },
  handleInactive({ commit, dispatch }) {
    dispatch("Logout");
  },
  startPollTimer({ state, commit, dispatch }) {
    if (state.pollTimerId != 0)
      clearTimeout(state.pollTimerId);

    let pollTimerId = setInterval(() => {
      let time = (new Date().getTime() - state.timeoutStart.getTime());
      let seconds = Math.round((state.timeoutInMs - time) / 1000);
      if (seconds < 0)
        seconds = 0;
      commit("setLogoutTime", seconds);
      if (seconds == 0) {
        dispatch("handleInactive");
        dispatch("stopPollTimer");
      }
    }, 1000);
    commit("setPollTimerId", pollTimerId);
  },
  stopPollTimer({ state, commit, dispatch }) {
    if (state.pollTimerId == 0)
      return;

    clearTimeout(state.pollTimerId);
    commit("setPollTimerId", 0);
  }
};

const mutations: MutationTree<User> = {
  login: (State, { payload, portalUser }) => {
    State.myName = payload.sub;
    State.jwtExpiration = payload.exp;
    State.loginTime = new Date().toLocaleString("de-DE");
    State.isLoggedIn = true;
    State.portalUser = portalUser;
  },
  logout: State => {
    State.myName = "";
    State.loginTime = "";
    State.jwtExpiration = 0;
    State.isLoggedIn = false;
    State.portalUser = null;
  },
  setPortalUser: (State, portalUser) => {
    State.portalUser = portalUser;
  },
  setTimeoutStart: (State) => {
    State.timeoutStart = new Date();
  },
  setPollTimerId: (State, timerId) => {
    State.pollTimerId = timerId;
  },
  setLogoutTime: (State, time) => {
    State.logoutTimeInSeconds = time;
  }
};

const namespaced: boolean = true;

export const auth: Module<User, RootStore> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
