

























































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import {
  PortalUserVM,
  SelectListItem,
  SchoolVM,
  SchoolClassVM
} from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExSubject } from "@/interfaces/ExSubject";
// import { ExBundleVM } from "../interfaces/ExBundleVM";
import { ExerciseVM } from "../interfaces/ExerciseVM";
import ExStatus from "../components/ExerciseComponents/ExStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExStatus
    // 'qrcode': VueQrcode
  }
})
export default class ExerciseBundleView extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedPupil!: PortalUserVM;
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  exercises: ExerciseVM[] = [];

  breadcrumbItems = [
    { text: "Start", disabled: false, href: "/home" },
    { text: "Übungen", disabled: false, href: "/exercises" },
    { text: "Übungspäckchen", disabled: true, href: "/exerciseBundle" }
  ];

  breadcrumbItemsFromExerciseAssignment = [
    { text: "Start", disabled: false, href: "/home" },
    { text: 'Übungsauswahl', disabled: false, href: '/exerciseAssignment' },
    { text: "Übungspäckchen", disabled: true, href: "/exerciseBundle" }
  ];
  async mounted() {
    if (this.selectedExBundleAssignment == null) {
      router.push("/");
      return;
    }
    this.getExercises();
    // if (this.selectedExBundleAssignment.showResult)
    this.updateExBundleAssignment();
  }

  async getExercises() {
    this.exercises = await rest
      .url("exercises/getExercises")
      .post(this.selectedExBundleAssignment);
  }

  async updateExBundleAssignment() {
    if (this.selectedExBundleAssignment.id == 0)
      return;

    let exBundle = await rest
      .url("exercises/updateExBundleAssignment")
      .post(this.selectedExBundleAssignment);
       this.$store.commit('globals/setExBundleAssignment', exBundle);
  }

  startExercise(exercise: ExerciseVM) {
    if (!exercise.enabled)
      return;

    this.$store.commit("globals/setExercise", exercise);
    router.push("/exercise");
  }

  goBack() {
    router.go(-1);
  }
}
