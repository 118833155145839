






























































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { List, Enumerable } from 'linq-collections';
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExSubject } from "@/interfaces/ExSubject";
import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM } from '../interfaces/ExBundleAssignmentGroupVM';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import { ExTagAchievementVM } from '@/interfaces/ExTagAchievementVM';

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
// Components
// import AssignmentMenu from '../components/ExerciseAssignment/AssignmentMenu.vue';
import IOSClientVersions from '../components/PerformanceOverview/iOSClientVersions';
import { ExCategory } from '@/interfaces/PortalUser';
import { KeyValue } from '@/interfaces/KeyValue';


const globals = namespace("globals");

@Component({
  components: {
      IOSClientVersions
    // 'qrcode': VueQrcode
  }
})
export default class PupilPerformanceOverview extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedSubject!: ExSubject;
  @globals.Getter selectedPupil!: PortalUserVM;
  sortAscending: boolean = true;
  showFilterMenu:boolean= false;
  subjects: ExSubject[] = [];
  localSubject: ExSubject | null = null;
  categories: ExCategory[] = [];
  selectedCategory: ExCategory | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  resultsOverview: ResultOverviewVM | null = null;
  tagAchievements: ExTagAchievementVM[] = [];

  chartData = {};
  iOsChartData = {};

  breadcrumbItems = [
      { text: 'Start', disabled: false, href: '/home' },
      { text: 'Meine Klassen', disabled: false, href: '/exercises' },
      { text: 'Schülerleistungsstand', disabled: true, href: '/pupilPerformanceOverview' },
  ];

  timeFrames: KeyValue[] = [
    { key: 'alles', value: 'all' },
    { key: '1 Woche', value: 'oneWeek' },
    { key: '1 Monat', value: 'oneMonth' },
    { key: '1. Halbjahr', value: 'firstHalfOfYear' },
    { key: '2. Halbjahr', value: 'secondHalfOfYear' }
  ];
  selectedTimeFrame: KeyValue  = this.timeFrames[2];

  async mounted() {
    if (this.selectedClass == null || this.selectedPupil == null) {
      router.push("/");
      return;
    }

    await this.getSubjects();
    await this.getCategories();
    await this.getPupilResults();
    await this.getTagAchievement();
    // this.getAssignmentsForAllExBundles();

    this.chartData = {
    labels: ['v4.08', 'v.4.0.10'],
      datasets: [
        {
          label: 'Data One',
          borderColor: '#f87979',
          //   backgroundColor: "#f87979",
          data: [40, 20]
        }
      ]
    };

    this.iOsChartData = {
      labels: ['v4.08', 'v.4.0.10'],
      datasets: [
        {
          borderColor: '#f0f0f050',
          //   backgroundColor: "#f87979",
          data: [40, 20],
          backgroundColor: ['#37BBA9', '#68E2D0']
        }
      ]
    };
  }

  trainingColor(achivement: ExTagAchievementVM) {
    if (achivement.notWellTrained)
      return "grey";

    return "light-blue";
  }

  async getPupilResults() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    // this.paramDir = <ParamDictionary>{ dictionary: {}};
    this.paramDir.dictionary["PupilId"] = this.selectedPupil.id!;
    this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString()!;

    this.resultsOverview = await rest.url("teacher/pupilPerformanceOverview").post(this.paramDir);
    // console.log(this.resultsOverview);
  }

  async getTagAchievement() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["sortAscending"] = this.sortAscending.toString()!;
    this.paramDir.dictionary["PupilId"] = this.selectedPupil.id!;
    this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString()!;
    this.paramDir.dictionary["SubjectId"] = (this.selectedSubject?.id ?? 0).toString();
    this.paramDir.dictionary["CategoryId"] = (this.selectedCategory?.id ?? 0).toString();
    this.paramDir.dictionary["timeFrame"] = this.selectedTimeFrame.value!;

    this.tagAchievements = await rest.url("teacher/loadTagAchievementList").post(this.paramDir);
    // console.log(this.resultsOverview);
  }

  async getSubjects() {
    this.subjects = await rest.url("exercises/getSubjects").query({ selectAll: true }, true).get();
    if (this.selectedSubject != null && this.selectedSubject.id != 0) {
      let subject = this.subjects.find(s => s.id == this.selectedSubject.id);
      this.$store.commit("globals/setSubject", subject);
    } else {
      this.$store.commit("globals/setSubject", this.subjects[0]);
    }
    this.localSubject = this.selectedSubject;
  }

  async changeSubject() {
      // console.log("subject changed" + this.localSubject)
      this.$store.commit("globals/setSubject", this.localSubject);
      await this.getCategories();
      this.getTagAchievement();
  }

  async getCategories() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["PupilId"] = this.selectedPupil.id!;
    this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString()!;
    this.paramDir.dictionary["SubjectId"] = this.selectedSubject.id.toString()!;

    this.categories = await rest.url("exercises/loadCategories").post(this.paramDir);
    this.selectedCategory = this.categories[0];
  }

  async changeCategory() {
      this.getTagAchievement();
  }

  get showActiveFilter() {
    if (this.selectedSubject && this.selectedSubject.id > 0)
      return true;
    if (this.selectedCategory && this.selectedCategory.id > 0)
       return true;
    if (this.selectedTimeFrame && this.selectedTimeFrame.value != "all")
       return true;
   return false;
  }

  goBack() {
    router.go(-1);
  }

  // async getAssignmentsForAllExBundles() {
  //   let pupilId: string | null = null;
  //   if (this.selectedPupil != null)
  //     pupilId = this.selectedPupil.id;

  //   let param: ParamGetExercises = {
  //     grade: this.grade,
  //     subjectId: this.selectedSubject.id,
  //     selectedSchoolClassId: this.selectedClass.id,
  //     selectedPupilId: pupilId,
  //     bundleId: 0 };
  //   this.exBundleGroups = await rest.url("teacher/getAssignmentsForAllExBundles").post(param);
  // }

  // async changeGrade(grade: number) {
  //     // console.log("grade changed, get exercises")
  //     this.grade = grade;
  //     this.getAssignmentsForAllExBundles();
  // }

  // async updateAssignment(assignment: ExBundleAssignmentVM) {
  //     if (assignment.alreadyAssignedToClass)
  //         return;

  //     assignment.isAssigned = !assignment.isAssigned;
  //     let updatedAssignment = await rest.url("exercises/").post(assignment);
  //     //assignment.id = updatedAssignment.id;
  //     this.getAssignmentsForAllExBundles();
  // }

  // async updateView() {
  //     console.log("ExerciseCatalog updateView");
  //     this.getAssignmentsForAllExBundles();
  // }

  // openResetResultsConfDialog(assignment: ExBundleAssignmentVM) {
  //     this.selectedAssignment = assignment;
  //     this.showResetResultsConfDialog = true;
  // }

  // closeDialog() {
  //     this.showResetResultsConfDialog = false;
  // }

  // async resetResults() {
  //     await rest.url("exercises/resetResults").post(this.selectedAssignment);
  //     this.showResetResultsConfDialog = false;
  // }

  // showExerciseBundle(bundle: ExBundleVM) {
  //     this.$store.commit('globals/setExBundleAssignment', bundle);
  //     router.push("/exerciseBundle");
  // }
}
