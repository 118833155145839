import Vuex, { StoreOptions } from "vuex";

import { RootStore } from "./types";
import Vue from "vue";
import { auth } from "./modules/auth";
import { ux } from "./modules/ux";
import { globals } from "./modules/globals";
import { quizplayerstore } from "./modules/quizplayerstore";
import { notify } from "./modules/notify";

Vue.use(Vuex);

export const store: StoreOptions<RootStore> = {
  state: {
    ready: false
  },
  mutations: {
    ready: state => {
      state.ready = true;
    }
  },
  modules: {
    auth,
    ux,
    globals,
    quizplayerstore,
    notify
  }
};

const Store: any = new Vuex.Store<RootStore>(store);

export default Store;
