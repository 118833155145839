import { ActionTree, GetterTree, MutationTree } from "vuex";

import { Module } from "vuex";
import { RootStore } from "../types";
import { Ux } from "../types/ux";

const state: Ux = {
  /* State */
  reserveSpaceForDrawer: false,
  /* Snackbar */
  snackbar: false,
  pending: false,
  success: false,
  failure: false,
  sbcontext: "info",
  sbtext: "",
  sbtimeout: 4000,
  /* Colors */
  darkTheme: true,
  // colorSuccess: "#64DD17",
  // colorInfo: "#039BE5",
  // colorWarning: "#F57C00",
  // colorError: "#F443364",
  // colorHeader1: "#FF9C10",
  // colorDivider1: "#FFC98A"
  colorSuccess: "white",
  colorInfo: "white",
  colorWarning: "white",
  colorError: "white",
  colorHeader1: "white",
  colorAccent: "#37BBA9",
  colorDivider1: "white",
  dcPurple: "#ae7fd4",
  dcPink: "#da178f",
  dcBlue: "#007aff",
  dcYellow: "#dad617",
  dcRed: "#ff0000",
  colorListBackground: "white",
  // App Version
  appVersion: "v 0.9.3",
  buildDate: "28.01.2023",
};

const getters: GetterTree<Ux, RootStore> = {};

const actions: ActionTree<Ux, RootStore> = {};

const mutations: MutationTree<Ux> = {
  /* State */
  ReserveSpaceForDrawer(state, isVisible: boolean) {
    state.reserveSpaceForDrawer = isVisible;
  },
  /* Snackbar */
  SB_PENDING(state) {
    state.pending = true;
  },
  SB_SUCCESS(state, data: any) {
    state.failure = false;
    state.sbcontext = "success";
    state.success = true;
    state.sbtext = data.message;
    state.snackbar = true;
    state.pending = false;
    if (data.timeout)
      state.sbtimeout = data.timeout;
    else
      state.sbtimeout = 2000;
  },
  SB_PRIMARY(state, data) {
    state.failure = false;
    state.sbcontext = "primary";
    state.success = true;
    state.sbtext = data.message;
    state.snackbar = true;
    state.pending = false;
    if (data.timeout)
    state.sbtimeout = data.timeout;
  else
    state.sbtimeout = 2000;
  },
  SB_FAILURE(state, data) {
    state.success = false;
    state.sbcontext = "error";
    state.failure = true;
    state.sbtext = data.message;
    state.snackbar = true;
    state.pending = false;
    if (data.timeout)
    state.sbtimeout = data.timeout;
  else
    state.sbtimeout = 0;
  },
  SB_WARNING(state, data) {
    state.success = false;
    state.sbcontext = "deep-orange darken-2";
    state.failure = true;
    state.sbtext = data.message;
    state.snackbar = true;
    state.pending = false;
    if (data.timeout)
      state.sbtimeout = data.timeout;
    else
      state.sbtimeout = 15000;
  },
  SB_UPDATE(state, value) {
    state.snackbar = value;
  },
  /* Colors */
  COLORS_DARK1(state) {
    state.darkTheme = true;
    state.colorSuccess = "#66BB6A";
    state.colorInfo = "#42A5F5";
    state.colorWarning = "#F57C00";
    state.colorError = "#F44336";
    state.colorHeader1 = "#FF9C10";
    state.colorAccent = "#FF9C10";
    state.colorDivider1 = "#FFC98A";
    state.colorListBackground = "#353535";
  },
  COLORS_DARK2(state) {
    state.darkTheme = true;
    state.colorSuccess = "#11B59F";
    state.colorInfo = "#42A5F5";
    state.colorWarning = "#F57C00";
    state.colorError = "#F44336";
    state.colorHeader1 = "#37BBA9";
    state.colorAccent = "#37BBA9";
    state.colorDivider1 = "#FFFFFF"; // "#11B59F";
    state.dcPurple = "#ae7fd4";
    state.dcPink = "#da178f";
    state.dcBlue = "#007aff";
    state.dcYellow = "#dad617";
    state.dcRed = "#ff0000";
    state.colorListBackground = "#353535";
  },
  COLORS_LIGHT(state) {
    state.darkTheme = false;
    state.colorSuccess = "#66BB6A";
    state.colorInfo = "#42A5F5";
    state.colorWarning = "#F57C00";
    state.colorError = "#F44336";
    state.colorHeader1 = "#37BBA9"; // "#FFA726";
    state.colorAccent = "#37BBA9"; // "#FFA726";
    state.colorDivider1 = "#FFFFFF"; // "#EF6C00";
    state.dcPurple = "#ae7fd4";
    state.dcPink = "#da178f";
    state.dcBlue = "#007aff";
    state.dcYellow = "#cac725";
    state.dcRed = "#ff0000";
    state.colorListBackground = "#FFFFFF";
  }
};

const namespaced: boolean = true;

export const ux: Module<Ux, RootStore> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
