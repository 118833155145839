











































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExSubject } from "@/interfaces/ExSubject";
// import { ExBundleVM } from '@/interfaces/ExBundleVM';
import { ParamBool } from '@/interfaces/ParamBool';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from '@/interfaces/ResultOverviewVM';
import ExBundleStatus from "../components/ExerciseComponents/ExBundleStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExBundleStatus
    // 'qrcode': VueQrcode
  }
})
export default class ExercisesView extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedPupil!: PortalUserVM;
  @globals.Getter selectedSubject!: ExSubject;
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  classes: SchoolClassVM[] | null = null;
  selectedClassId: number = 0;
  pupils: PortalUserVM[] | null = null;
  selectedPupilId: string | null = null;
  subjects: ExSubject[] | null = [];
  bundleAssignments: ExBundleAssignmentVM[] | null = [];
  subjectParam: ParamBool = { value: true };
  paramDir: ParamDictionary = { dictionary: {}};
  resultsOverview: ResultOverviewVM | null = null;
  selectAll: boolean = true;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Übungen', disabled: true, href: '/exercises' },
 ];

  async mounted() {
    // if (this.selectedClass == null) {
    //   router.push("/");
    //   return;
    // }
    if (this.selectedClass)
      this.selectedClassId = this.selectedClass?.id ?? 0;
    else
      this.selectedClassId = 0;

    if (this.selectedPupil)
      this.selectedPupilId = this.selectedPupil?.id;
    else
      this.selectedPupilId = null;

    if (this.isTeacher) {
      await this.getClasses();
      await this.getPupils();
    }

    if (this.isPupil || this.isParent) {
      await this.getResults();
    }

    //this.$store.commit("globals/setSubject", null); // On open show all subjects
    await this.getSubjects();
    if (this.selectedClass)
      this.getExBundels();
  }

  async getClasses() {
    this.classes = await rest.url("teacher/getMyClasses").get();
    if (this.classes == undefined || this.classes.length == 0) {
      this.selectedClassId = 0;
      this.$store.commit("globals/setSchoolClass", null);
      return;
    }

    // if (this.classes.length > 0 && this.selectedClass != null) {
    //   let lastSelectedClass = this.classes.find(c => c.id == this.selectedClass.id);
    //   if (lastSelectedClass != null) {
    //     this.selectedClassId = lastSelectedClass.id;
    //   }
    // }

    // Select first class
    if (this.selectedClassId == 0) {
      this.selectedClassId = this.classes[0].id;
      this.$store.commit("globals/setSchoolClass", this.classes[0]);
      return;
    }
  }

  async getPupils() {
    if (this.selectedClassId == 0)
      return;

    this.pupils = await rest.url("teacher/getPupils").query({ schoolClassId: this.selectedClassId, addSelectAll: true }, true).get();
    if (this.pupils!.length > 0 && this.selectedPupil != null) {
      let selectedPupil = this.pupils!.find(p => p.id == this.selectedPupil.id);
      if (selectedPupil != null) {
        this.selectedPupilId = this.selectedPupil.id;
        return;
      }
    }
    if (this.selectedPupil != null) {
      this.selectedPupilId = null;
      this.$store.commit("globals/setPupil", null);
    }
  }

  async getSubjects() {
    this.subjects = await rest.url("exercises/getSubjects").query({ selectAll: true }, true).get();
    this.$store.commit("globals/setSubject", this.subjects![0]);
  }

  async getResults() {
    this.resultsOverview = await rest.url("exercises/getResultsOverview").get();
    // console.log(this.resultsOverview);
  }

  async getExBundels() {
    // let pupilId: string = null;
    // if (this.selectedPupil != null)
    //   pupilId = this.selectedPupil.id;

    let param: ParamGetExercises = {
      grade: 0,
      subjectId: this.selectedSubject.id,
      selectedSchoolClassId: this.selectedClassId,
      selectedPupilId: this.selectedPupilId,
      bundleId: 0
    };
    this.bundleAssignments = await rest.url("exercises/getAssignedExBundles").post(param);
  }

  async onClassChanged() {
    let selectedSchoolClass = this.classes!.find(c => c.id == this.selectedClassId);
    this.$store.commit("globals/setSchoolClass", selectedSchoolClass);
    await this.getPupils();
    this.getExBundels();
  }

  onPupilChanged() {
    if (this.selectedPupilId != null) {
      let selectedPupil = this.pupils!.find(p => p.id == this.selectedPupilId);
      this.$store.commit("globals/setPupil", selectedPupil);
    } else {
      this.$store.commit("globals/setPupil", null);
    }
    this.getExBundels();
  }

  async changeSubject(subject: ExSubject) {
    this.$store.commit("globals/setSubject", subject);
    if (this.selectedClass)
      this.getExBundels();
  }

  showExerciseBundle(assignment: ExBundleAssignmentVM) {
    this.$store.commit('globals/setExBundleAssignment', assignment);
    if (this.selectedPupilId != null) {
      router.push("/exerciseBundle");
    } else {
      router.push("/classExerciseOverview");
    }
  }

  goBack() {
    router.go(-1);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
