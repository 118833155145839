




























































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import QuizPlayer from '../components/ExerciseComponents/QuizPlayer.vue';
import ExFinished from '../components/ExerciseComponents/ExFinished.vue';
import { ExDataSet, ExChoice, ExQuestion } from '../interfaces/ExDataSet';
import { ExerciseVM } from '../interfaces/ExerciseVM';
// import { ExBundleVM } from '@/interfaces/ExBundleVM';
import de from 'vuetify/src/locale/de';
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

const globals = namespace("globals");

@Component({
    components: {
        QuizPlayer,
        ExFinished
    }
})
export default class Exercise extends Vue {
    @globals.Getter selectedExercise!: ExerciseVM;
    @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
    exProgress: number = 0;
    showFinishDialog: boolean = false;
    resultInPercent: number = 0;

    // $refs!: {};

    breadcrumbItems = [
        { text: 'Start', disabled: false, href: '/home' },
        { text: 'Übungen', disabled: true, href: '/exercises' },
    ];

    mounted() {
        if (this.selectedExercise == null) {
            router.push("/");
            return;
        }
        //this.$refs.backButton.scrollIntoView();
        this.$globalHelper.scrollToTop();
    }

    @Watch("showFinishDialog")
    async onShowFinishDialogChanged(val: boolean) {
        if (!this.showFinishDialog) {
            // window.alert("Finish dialog closed!");
            this.goBack();
        }
    }

    setProgress(progress: number) {
        this.exProgress = progress;
    }

    quizFinished(resultInPercent: number) {
        this.resultInPercent = resultInPercent;
        this.exProgress = 100;
        if (this.selectedExercise.isRule || this.selectedExercise.image == "mdi-filmstrip") {
            this.goBack();
        } else {
            this.showFinishDialog = true;
        }
    }

    goBack() {
        router.go(-1);
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
