import wretch, { WretcherError } from "wretch";

// import { AspError } from "@/interfaces/AspError";
// import { Anonymous } from "@/interfaces/RestStatusResponse`1";
import { RestResponse } from "@/interfaces/RestResponse";
import { VueInstance } from "./main";

// JWT
let token = window.localStorage.getItem("digiClassAuth");
export function refreshJwt(): string | null {
  token = window.localStorage.getItem("digiClassAuth");
  return token;
}

const helper = {
  endWait(resurl: string) {
    VueInstance.$wait.end(resurl.replace(/^.*\/\/[^\/]+/, ""));
  },
  wretchErr(err: WretcherError): RestResponse | null {
    this.endWait(err.response.url);
    if (err.text == null) {
      return null;
    } else {
      try {
        const status: RestResponse = JSON.parse(err.text);
        return status;
      } catch {
        return {
          code: "BadError",
          description: "Could not parse error",
          traceId: ""
        } as RestResponse;
      }
    }
  }
};

const rest = wretch()
  .defer((w, url, options) => {
    // console.log("url: ", url);
    // console.log("token: ", token);
    VueInstance.$wait.start(url);
    return w.auth(`Bearer ${token}`);
  })
  .resolve(resp =>
    resp
      .badRequest(badRequest => {
        const apiError = helper.wretchErr(badRequest);
        if (apiError)
          VueInstance.$store.commit("ux/SB_FAILURE", {
            message: `Service Fehler - ${apiError.description}`,
            timeout: 0
          });
      })
      .forbidden(forbidden => {
        const apiError = helper.wretchErr(forbidden);
        if (apiError)
          VueInstance.$store.commit("ux/SB_FAILURE", {
            message: `Zugriff verweigert - ${apiError.description}`,
            timeout: 0
          });
      })
      .unauthorized(unauthorized => {
        // const apiError = helper.wretchErr(unauthorized);
        // if (apiError)
        //   VueInstance.$store.commit("ux/SB_FAILURE", {
        //     message: `Nicht authoriziert - ${apiError.description}`
        //   });
        // Stop loader
        helper.endWait(unauthorized.response.url); // have to be tested if it works
        VueInstance.$store.commit("ux/SB_FAILURE", {
          message: `Anmeldung erforderlich`,
          timeout: 8000
        });
        VueInstance.$store.commit("auth/logout");
        VueInstance.$router.push("/");
      })
      .internalError(internalError => {
        const apiError = helper.wretchErr(internalError);
        if (apiError)
          VueInstance.$store.commit("ux/SB_FAILURE", {
            message: `Interner Server Error - ${apiError.description}`,
            timeout: 0
          });
      })
      .notFound(notFound => {
        const apiError = helper.wretchErr(notFound);
        if (apiError)
          VueInstance.$store.commit("ux/SB_WARNING", {
            message: `Nicht gefunden - ${apiError.description}`,
            timeout: 0
          });
      })
      .res(async res => {
        // Stop loader
        helper.endWait(res.url);
        let jsonResult: RestResponse = await res.json();
        if (jsonResult.description != null) {
          VueInstance.$store.commit("ux/SB_SUCCESS", {
            message: jsonResult.description,
            timeout: 3000
          });
        }
        return jsonResult.response;

        //.then(resJson => {
        //  console.log(resJson.response);
        //  return resJson.response!;
        //})
        //.catch(err => {
        //  if (err instanceof SyntaxError) {
        //    return "";
        //  }
        //});
      })
  )
  .url("/api/");

export default rest;
