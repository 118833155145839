






































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import rest from '@/rest';
import _ from "lodash";
import router from '@/router';
import { List } from 'linq-collections';
import FileDrop from '@/components/FileDrop.vue';

// interfaces
import { TopicNotificationVm } from '@/interfaces/TopicNotificationVm';
import { TopicVm } from '@/interfaces/TopicVm';
import { ParamDictionary } from '@/interfaces/ParamDictionary';

@Component({
  components: {
    FileDrop
  }
})
export default class DlgNotification extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() editMode!: boolean;
  @Prop() notification!: TopicNotificationVm;

  busy = false;
  topics: TopicVm[] = [];
  selectedTopics: TopicVm[] = [];
  // notificationTitle = "";
  // notificationText = "";
  // notificationHtmlText = "";
  // imageData: string | null = null;
  localNotification: TopicNotificationVm = {} as TopicNotificationVm;

  @Watch('showDialog')
  async onShowDialogChanged(open) {
    if (open) {
      if (this.notification) {
        this.localNotification = _.cloneDeep(this.notification);
        await this.loadTopics();
        this.selectedTopics = new List(this.topics).where(t => this.localNotification.topics!.includes(t.value!)).toArray();
      } else {
        this.localNotification = {} as TopicNotificationVm;
      }
    }
  }

  async loadTopics() {
    this.topics = await rest.url('notification/getTopics').get();
  }

  onFileLoaded(fileInfo: ParamDictionary) {
    let base64image: string = fileInfo.dictionary!['Data'];
    if (!base64image.startsWith('data:image')) {
        this.$store.commit("ux/SB_FAILURE", {
          message: "Falsches Dateiformat",
          timeout: 0
        });

      this.localNotification.image = "";
      return;
    }
    this.localNotification.image = base64image;
    if(base64image.length > 700000) {
      this.$store.commit("ux/SB_WARNING", {
        message: "Bild zu groß!",
        timeout: 3000
      });
    }
  }

  async onSendNotification() {
    if (this.busy == true)
      return;
    this.busy = true;

    let params = <TopicNotificationVm>{
      topics: new List(this.selectedTopics).select(t => t.value).toArray(),
      title: this.localNotification.title,
      body: this.localNotification.body,
      htmlBody: this.localNotification.htmlBody,
      image: this.localNotification.image,
      deepLink: '',
      urls: <string[]>[],
      createdDate: new Date(),
      noted: false
    };
    rest.url('notification/sendTopicNotification')
    .post(params)
    .then(() => {
      this.$store.commit("ux/SB_SUCCESS", {
        message: "Benachrichtigung verschickt",
        timeout: 3000
      });

      // Clear form
      // this.notificationTitle = "";
      // this.notificationText = "";
      // this.notificationHtmlText = "";
      // this.imageData = null;
    })
    .finally(() => {
      this.busy = false;
      this.$emit("update:notification");
      this.syncedShowDialog = false;
    });
  }

  async onSave() {
    if (this.busy == true)
      return;

    this.busy = true;
    this.localNotification.topics = new List(this.selectedTopics).select(t => t.value!).toArray();

    rest.url('notification/updateTopicNotification')
    .post(this.localNotification)
    .then(() => {
      this.$store.commit("ux/SB_SUCCESS", {
        message: "Benachrichtigung gespeichert",
        timeout: 3000
      });
    })
    .finally(() => {
      this.busy = false;
      this.$emit("update:notification");
      this.syncedShowDialog = false;
    });
  }

  get dlgBackgroundColor() {
    if (this.localNotification.disabled)
      return this.$vuetify.theme.dark ? "#455A64" : "#B0BEC5";
    return "";
  }

  get dlgValid() {
    if (!this.localNotification.title || this.localNotification.title.length < 4)
      return false;
    if (!this.localNotification.body || this.localNotification.body.length < 4)
      return false;
    if (!this.localNotification.image || this.localNotification.image.length < 5)
      return false;
    if  (!this.selectedTopics || this.selectedTopics.length == 0)
      return false;

    return true;
  }
}
