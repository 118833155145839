import App from "./App.vue";
import Vue from "vue";
import VueWait from "vue-wait";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import './firebase.ts';
import "./plugins/vuetify";
import "./plugins/vuewait";
import "./plugins/veevalidate";
import "./plugins/gh";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import "./registerServiceWorker";

// Moment
// https://stackoverflow.com/questions/34308004/moment-js-with-vuejs
import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/de");
//Vue.use(require("vue-moment"));
Vue.use(VueMoment, { moment });

Vue.config.productionTip = false;

// https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/#:~:text=Essentially%2C%20an%20event%20bus%20is%20a%20Vue.js%20instance,emitters%20because%20it%20requires%20less%20code%20to%20run.
export const quizPlayerEventBus = new Vue();

export const VueInstance = new Vue({
  render: h => h(App),
  router,
  vuetify,
  store,
  wait: new VueWait()
}).$mount("#app");
