import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { RootStore } from "../types";
import { List } from "linq-collections";
import { QuizPlayerStore } from "../types/quizplayerstore";
import {
  ExDataSet,
  ExViewElement,
  ExChoiceList,
  ExChoiceListItem,
  ExChoice,
  ExQuestion
} from "@/interfaces/ExDataSet";

const state: QuizPlayerStore = {
  disableVerifyButton: false,
  childQuestions: null,
  selectedChildQuestionId: 0,
  choiceLists: []
};

const getters: GetterTree<QuizPlayerStore, RootStore> = {
  childQuestions: State => {
    return State.childQuestions;
  },
  selectedChildQuestionId: State => {
    return State.selectedChildQuestionId;
  },
  selectedChildQuestion: State => {
    if (!State.childQuestions)
      return null;

    return State.childQuestions[State.selectedChildQuestionId];
  },
  choicesRightAnswered: State => {
    return !new List(State.choiceLists).any(l => l.state == "F");
  },
  allChoicesSelected: State => {
    if (new List(State.choiceLists).any(l => l.state == "NA"))
      return false;
    return true;
  }
};

const actions: ActionTree<QuizPlayerStore, RootStore> = {
};

const mutations: MutationTree<QuizPlayerStore> = {
  setChildQuestions: (State, value: ExQuestion[] | null) => {
    State.childQuestions = value;
  },
  setSelectedChildQuestionId: (State, value: number) => {
    State.selectedChildQuestionId = value;
  },
  setSelectedChildQuestion: (State, value: ExQuestion) => {
    if (!State.childQuestions)
      return;

    State.childQuestions[State.selectedChildQuestionId] = value;
  },
  setChoices: (State, choiceLists: ExChoiceList[]) => {
    if (!choiceLists)
      State.choiceLists = [];

    State.choiceLists = choiceLists;
  },
  setChoiceState: (State,  params: { id: number, isRight: boolean }) => {
    let choiceList = new List(State.choiceLists).singleOrDefault(l => l.id == params.id);
    if (!choiceList) return;
    choiceList.state = params.isRight ? "T" : "F";
    // Enable verify button if all selectBoxes have selections => separat getter
    // if (!new List(this.selectLists).any(l => l.state == "NA"))
    //   this.disableVerifyButton = false;
  }
};

const namespaced: boolean = true;

export const quizplayerstore: Module<QuizPlayerStore, RootStore> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};