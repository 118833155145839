





// import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompText extends Vue {
    @Prop() text!: string;
    @Prop() textArray!: string[];
    @Prop() size!: string; // e.g. 24, 24px
    @Prop() color!: string; // e.g. #00A1EF
    @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
    @Prop() fontStyle!: string; // normal, italic, oblique

    // $refs!: {};

    mounted() {}

    get fontSize() {
        if (this.size == null)
            return "24px";
        if (isNaN(Number(this.size))) // Number(this.size) != NaN)
        return this.size;
        return this.size + "px";
    }


    get hyperText() {
        if (this.text != null)
            return this.text;
        if (this.textArray != null)
            return this.textArray.join('');

        return null;
    }
}
