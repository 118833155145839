import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { RootStore } from "../types";
import { List } from "linq-collections";
import { NotifyStore } from "../types/notify";
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { FirebaseSubscribeToTopicVm } from '@/interfaces/FirebaseSubscribeToTopicVm';
import rest from '@/rest';
// import { vuex } from '@/store';
import Store from '@/store';
import { TopicNotificationVm } from '@/interfaces/TopicNotificationVm';
import { TopicVm } from '@/interfaces/TopicVm';
import { State } from "vuex-class";
import { UnsubscribedTopic } from "@/interfaces/UnsubscribedTopic";
import { UserNotificationVm } from "@/interfaces/UserNotificationVm";
import { UpdateTopicSubscriptionsVm } from "@/interfaces/UpdateTopicSubscriptionsVm";
// import Helper from '@/classes/helper';
// import i18n from '@/i18n';


const state: NotifyStore = {
  fcmToken: "",
  fcMessaging: <Messaging>{},
  // notifications: <TopicNotificationVm[]>[]
  topicNotifications: <TopicNotificationVm[]>[],
  userNotifications: <UserNotificationVm[]>[]
};

const getters: GetterTree<NotifyStore, RootStore> = {
  hasNewTopicNotifications: State => {
    return State.topicNotifications.findIndex(n => !n.noted) >= 0;
  },
  newTopicNotificationCount: State => {
    return State.topicNotifications.filter(n => !n.noted).length;
  },
  hasNewUserNotifications: State => {
    return State.userNotifications.findIndex(n => !n.noted) >= 0;
  },
  newUserNotificationCount: State => {
    return State.userNotifications.filter(n => !n.noted).length;
  },
  isPushSupportedByBrowser: State => {
    return (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'Notification' in window
    );
  }
};

const actions: ActionTree<NotifyStore, RootStore> = {
  async registerFcm({ commit, dispatch, getters, state }) {
    commit("setMessaging", getMessaging());

    // Register client for notifications
    // https://firebase.google.com/docs/cloud-messaging/js/client#retrieve-the-current-registration-token

    let fcmToken = await getToken(state.fcMessaging, {
      vapidKey: 'BLS2KOraDq1idbOPQ6R1n8nawteAqIwh8-tjD0a2lfkcWgWY934Xe0KkU35_0VZdCePyWJ8PXqe_c0J3mTUKYOw',
      serviceWorkerRegistration: await window.navigator.serviceWorker.getRegistration()
    }).catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // commit("ux/SB_FAILURE", {
      //   message: `FCM error: ${err}`,
      //   timeout: 0
      // }, { root: true });
    });

    if (fcmToken) {
      commit("setFcmToken", fcmToken);
      console.log('Firebase registration successful. Token: ' + fcmToken);

      // dispatch("subscribeTopics");
      // await this.fcmRegisterClient();
      dispatch("fcmRegisterClient");
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }

    const broadcast = new BroadcastChannel('digiclassbcch');
    broadcast.onmessage = (msg: MessageEvent) => {
      console.log("broadcast received: ", msg.data);
      if (msg.data === 'open') {
        window.open('https://digiclass-online.de', '_blank');
        // vuex.ux.SB_SUCCESS(`${VueInstance.$t('shared.notification.opened')}: ${msg.data}`);
        commit("ux/SB_SUCCESS", {
          message: `Notification opened: ${msg.data}`,
          timeout: 3000
        }, { root: true });
      } else {
        dispatch("loadTopicNotifications");
        dispatch("loadUserNotifications");
        // vuex.ux.SB_SUCCESS(`${VueInstance.$t('shared.notification.received')}: ${msg.data}`);
        commit("ux/SB_SUCCESS", {
          message: `Notification received: ${msg.data}`,
          timeout: 3000
        }, { root: true });
      }
      //  let msgObj = JSON.parse(msg.data)
      //  console.log(msgObj)
      //  this.addTopicNotification(msgObj)
      //console.log(msgObj.notification.body)

      //      window.open(payload.notification.click_action , '_blank');
      //window.open('http://localhost:8611/connect' , '_blank');
    };
  },
  async fcmRegisterClient({ commit, dispatch, getters, state }) {
    let result: boolean = await rest.url('notification/fcmRegisterClient').query({token: state.fcmToken, userAgent: navigator.userAgent}).get();
    if (result == true) {
      commit("ux/SB_SUCCESS", {
        message: "Erfolgreich für Benachrichtigungen registriert",
        timeout: 3000
      }, { root: true });
    } else {
      commit("ux/SB_SUCCESS", {
        message: "Fehler beim registriert für Benachrichtigungen",
        timeout: 3000
      }, { root: true });
    }
  },
  async updateTopics({ commit, dispatch, getters, state }, topics: TopicVm[]) {
    //await rest.url('notification/saveUnsubscribedTopics').post(topics.filter(t => !t.selected));
    let params = <UpdateTopicSubscriptionsVm>{ registrationToken: state.fcmToken, topics: topics };
    let result: boolean = await rest.url('notification/updateTopicSubscriptions').post(params);
    if (result == true) {
      commit("ux/SB_SUCCESS", {
        message: "Erfolgreich gespeichert",
        timeout: 3000
      }, { root: true });
    } else {
      commit("ux/SB_SUCCESS", {
        message: "Fehler beim speichern der Kategorien!",
        timeout: 3000
      }, { root: true });
    }
  },
  // async subscribeTopics({ commit, dispatch, getters, state }) {
  //   let topics: TopicVm[] = await rest.url('notification/getTopics').get();
  //   let unsubscribedTopics: UnsubscribedTopic[] = await rest.url('notification/getUnsubscribedTopics').get();
  //   let result = true;
  //   topics.forEach(async t => {
  //     let params = <FirebaseSubscribeToTopicVm>{ registrationToken: state.fcmToken, topic: t.value };
  //     if( unsubscribedTopics.some(u => u.id === t.id)) {
  //       result = result && await rest.url('notification/unsubscribeToTopic').post(params);
  //     } else {
  //       result = result && await rest.url('notification/subscribeToTopic').post(params);
  //     }
  //   });

  //   if (result == true) {
  //     // vuex.ux.SB_SUCCESS(i18n.t(VueInstance.$hg.tl.toolbar.notifications.allTopicSubscribed));
  //     commit("ux/SB_SUCCESS", {
  //       message: "Erfolgreich zu allen Topics registriert",
  //       timeout: 3000
  //     }, { root: true });
  //   } else {
  //     // vuex.ux.SB_FAILURE(i18n.t(VueInstance.$hg.tl.toolbar.notifications.errTopicSubscribed));
  //     commit("ux/SB_SUCCESS", {
  //       message: "Fehler beim registriert zu den Topics",
  //       timeout: 3000
  //     }, { root: true });
  //   }
  // },
  // async fcmSubscribeTopic({ commit, dispatch, getters, state }, topic: string) {
  //   // Jan: check noch einbauen ob nicht bereits subscribed.
  //   let params = <FirebaseSubscribeToTopicVm>{registrationToken: state.fcmToken, topic: topic};
  //   let result = await rest.url('notification/subscribeToTopic').post(params);

  //   if (result == true) {
  //     // vuex.ux.SB_SUCCESS(i18n.t(VueInstance.$hg.tl.toolbar.notifications.topicSubscribed) + ' ' + topic)
  //     commit("ux/SB_SUCCESS", {
  //       message: "Topic erfolgreich registriert",
  //       timeout: 3000
  //     }, { root: true });
  //   }
  // },
  // async fcmUnsubscribeTopic({ commit, dispatch, getters, state }, topic: string) {
  //   let params = <FirebaseSubscribeToTopicVm>{ registrationToken: state.fcmToken, topic: topic };
  //   let result = await rest.url('notification/unsubscribeToTopic').post(params);

  //   if (result == true) {
  //     // vuex.ux.SB_SUCCESS(i18n.t(VueInstance.$hg.tl.toolbar.notifications.topicUnsubscribed) + ' ' + topic)
  //     commit("ux/SB_SUCCESS", {
  //       message: "Topic erfolgreich entfernt",
  //       timeout: 3000
  //     }, { root: true });
  //   }
  // },
  async loadTopicNotifications({ commit, dispatch, getters }) {
    rest
      .url('notification/getTopicNotifications')
      .get()
      .then(async (result: TopicNotificationVm[]) => {
        await dispatch("saveTopicNotifications", result);
      })
      .catch(err => {
        // Helper.handleException(err);
      });

    // if (vuex.onlineChecker.isOnline) {
    // } else {
    //   const pData = localStorage.getItem(this.ukeyNotifications);
    //   if (!pData) {
    //     return;
    //   }
    //   let list = JSON.parse(pData) as TopicNotificationVm[];
    //   this.setTopicNotifications(list);
    // }
  },
  async saveTopicNotifications({ commit, dispatch, getters }, list: TopicNotificationVm[]) {
    list = new List(list)
      .orderBy(n => n.noted)
      .thenByDescending(n => n.createdDate)
      .toArray();
      commit("setTopicNotifications", list);
    // let tempList = Helper.copyObject(list);
    // tempList.forEach(n => {
    //   n.image = '';
    // });
    // localStorage.setItem(this.ukeyNotifications, JSON.stringify(tempList));
  },
  async topicNotificationsNoted({ commit, dispatch, getters, state }) {
    if (getters.hasNewTopicNotifications) {
      // set noted flag local !!! Robert: Maybe action should be change to mutation !!!
      state.topicNotifications.forEach(n => {
        n.noted = true;
      });
      // let list = Helper.copyObject(this.notifications);
      // list.forEach(n => {
      //   n.image = '';
      // });
      // localStorage.setItem(this.ukeyNotifications, JSON.stringify(list));

      await dispatch("sendTopicNotificationNoted");
      // if (vuex.onlineChecker.isOnline) {
      //   await this.sendTopicNotificationNoted();
      // }
    }
  },
  async sendTopicNotificationNoted({ commit, dispatch, getters }) {
    // send noted flag to backend
    rest
      .url('notification/topicNotificationsNoted')
      .post()
      .then(result => {
        // console.log(result);
      })
      .catch(err => {
        // Helper.handleException(err);
      });
  },
  // user notifications
  async loadUserNotifications({ commit, dispatch, getters }) {
    rest
      .url('notification/getUserNotifications')
      .get()
      .then(async (result: UserNotificationVm[]) => {
        await dispatch("saveUserNotifications", result);
      })
      .catch(err => {
        // Helper.handleException(err);
      });
  },
  async saveUserNotifications({ commit, dispatch, getters }, list: UserNotificationVm[]) {
    list = new List(list)
      .orderBy(n => n.noted)
      .thenByDescending(n => n.createdDate)
      .toArray();
      commit("setUserNotifications", list);
  },
  async userNotificationsNoted({ commit, dispatch, getters, state }) {
    if (getters.hasNewUserNotifications) {
      // set noted flag local !!! Robert: Maybe action should be change to mutation !!!
      state.userNotifications.forEach(n => {
        n.noted = true;
      });
      await dispatch("sendUserNotificationNoted");
      // if (vuex.onlineChecker.isOnline) {
      //   await this.sendTopicNotificationNoted();
      // }
    }
  },
  async sendUserNotificationNoted({ commit, dispatch, getters }) {
    rest
      .url('notification/userNotificationsNoted')
      .post()
      .then(result => {
        // console.log(result);
      })
      .catch(err => {
        // Helper.handleException(err);
      });
  },
};

const mutations: MutationTree<NotifyStore> = {
  setMessaging(State, v: Messaging) {
    State.fcMessaging = v;
  },
  setFcmToken(State, v: string) {
    State.fcmToken = v;
  },
  setTopicNotifications(State, list: TopicNotificationVm[]) {
    State.topicNotifications = list;
  },
  // addTopicNotification(State, msgObj: any) {
  //   State.topicNotifications.push(msgObj);
  // },
  setUserNotifications(State, list: UserNotificationVm[]) {
    State.userNotifications = list;
  },
};

const namespaced: boolean = true;

export const notify: Module<NotifyStore, RootStore> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
