















































































































































import _ from "lodash";
import rest from "@/rest";
import router from "@/router";
import { Component, Vue, Watch } from "vue-property-decorator";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
// import { School } from "@/interfaces/School";
import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";
import EditSchoolClass from "@/components/SchoolClasses/EditSchoolClass.vue";

@Component({
  components: {
    EditSchoolClass
  }
})
export default class SchoolClassesView extends Vue {
  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Meine Klassen', disabled: true, href: '/schoolClasses' },
  ];

  // School classes
  // showNewClassDialog = false;
  showEditClassDialog: boolean = false;
  showDelteDialog: boolean = false;
  newClass: boolean = true;
  // selectedClass: SchoolClassVM | null = null;
  defaultClass: SchoolClassVM = {
    id: 0,
    year: 0,
    schoolYear: "",
    schoolId: 0,
    schoolName: "",
    grade: 0,
    title: "",
    name: "",
    // classLeaderId: "",
    classLeaderId: (this.$store.state.auth.portalUser as PortalUserVM).id,
    classLeaderName: (this.$store.state.auth.portalUser as PortalUserVM).fullName,
    allowModify: true,
    classTeachers: [] as PortalUserVM[],
    pupils:[] as  PortalUserVM[]
  };
  // newClass: SchoolClass = _.cloneDeep(this.defaultClass);
  editClass: SchoolClassVM =  _.cloneDeep(this.defaultClass);
  deleteClass: SchoolClassVM =  _.cloneDeep(this.defaultClass);
  newClassFormValid: boolean = true;
  schoolClasses: SchoolClassVM[] = [];

  // Pupils
  // showNewPupilDialog = false;
  //showEditPupilDialog = false;
  //selectedPupilIdx: number = -1;
  // defaultUser: PortalUserVM = {
  //   id: "0",
  //   firstName: "",
  //   lastName: "",
  //   nickname: "",
  //   fullName: "",
  //   userName: "",
  //   email: "",
  //   disabled: false,
  //   selectRoles: [] as SelectListItem[],
  //   roles: [],
  //   showMenu: false,
  //   selected: false,
  //   avatar: "",
  //   disableAvatar: false,
  //   gdprApprovalDate: null,
  //   gdprApprovalRequired: false,
  //   activationKey: "",
  //   schoolId: 0,
  //   schoolClassId: 0,
  //   schools: [] as SchoolVM[],
  //   classLeaderOf: [] as SchoolClassVM[],
  //   schoolClasses: [] as SchoolClassVM[]
  // };
  // editPupil: PortalUserVM =  _.cloneDeep(this.defaultUser);
  // newPupilFormValid: boolean = true;
  // pupils: PortalUserVM[] = [];

  $refs!: {
    addNewClassForm: HTMLFormElement;
    // addNewPupilForm: HTMLFormElement;
  };

  mounted() {
    this.updateClassList();
  }

  async updateClassList() {
    // this.schoolClasses = await rest.url("teacher/GetClassesWithClassLead").get();
    this.schoolClasses = await rest.url("teacher/getMyClassesExt").get();
  }

  // --- School Functions ---
  onAddClass() {
    this.editClass = _.cloneDeep(this.defaultClass);
    this.newClass = true;
    this.showEditClassDialog = !this.showEditClassDialog; // Always toggle to trigger watch in component
  }

  // onExerciseCatalog(selectedClass: SchoolClassVM) {
  //   if (selectedClass == null)
  //     return;

  //   this.$store.commit("globals/setSchoolClass", selectedClass);
  //   this.$store.commit("globals/setPupil", null);
  //   router.push("/exerciseCatalog");
  // }

  onShowPupils(selectedClass: SchoolClassVM) {
    if (selectedClass == null)
      return;

    this.$store.commit("globals/setSchoolClass", selectedClass);
    router.push("/pupils");
  }

  onEditClass(selectedClass: SchoolClassVM) {
    if (selectedClass == null)
      return;

    this.editClass = _.cloneDeep(selectedClass);
    this.newClass = false;
    this.showEditClassDialog = !this.showEditClassDialog; // Always toggle to trigger watch in component
  }

  showDeleteConfirmationDlg(schoolClass: SchoolClassVM) {
    if (schoolClass == null)
      return;

    this.deleteClass = schoolClass;
    this.showDelteDialog = true;
  }

  async onDeleteClass() {
    const index = this.schoolClasses.indexOf(this.deleteClass);
    await rest.url("teacher/deleteClass").post(this.deleteClass);
    this.schoolClasses.splice(index, 1);
    this.showDelteDialog = false;
  }


  // Validation
  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  mailEditRules = [
    (v: string) =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz"
  ];

  schoolNameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 3) || "Name zu kurz"
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
  ];

  keyEditRules = [
    (v: string) =>
      !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz"
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
  ];
}
