import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { RootStore } from "../types";
import { Globals } from "../types/globals";
import { SchoolClassVM, PortalUserVM } from "@/interfaces/PortalUserVM";
import { ExBundleVM } from "@/interfaces/ExBundleVM";
import { ExerciseVM } from "@/interfaces/ExerciseVM";
import { ExSubject } from "@/interfaces/ExSubject";
import rest from "@/rest";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
// import { ExDataSet, ExViewElement, ExChoiceList, ExChoiceListItem, ExChoice, ExQuestion } from "@/interfaces/ExDataSet";

const state: Globals = {
  selectedSchoolClass: null,
  pupilsOfSelectedClass: null,
  selectedSubject: null,
  selectedPupil: null,
  selectedExBundleAssignment: null,
  selectedExercise: null,
  isInPrintMode: false,
  showPrintPupilsQrCode: false,

  // CompMultipleSteps
  // childQuestions: null,
  // selectedChildQuestionId: 0,
  // selectedStepQuestion: null,

  // classExerciseOverview
  classExerciseOverviewSortByAlphabet: true,
  classExerciseOverviewSortOrderAscending: true,
};

const getters: GetterTree<Globals, RootStore> = {
  selectedPupil: State => {
    return State.selectedPupil;
  },
  selectedClass: State => {
    return State.selectedSchoolClass;
  },
  pupilsOfSelectedClass: State => {
    return State.pupilsOfSelectedClass;
  },
  selectedSubject: State => {
    return State.selectedSubject;
  },
  selectedExBundleAssignment: State => {
    return State.selectedExBundleAssignment;
  },
  selectedExercise: State => {
    return State.selectedExercise;
  },
  isInPrintMode: State => {
    return State.isInPrintMode;
  },
  showPrintPupilsQrCode: State => {
    return State.showPrintPupilsQrCode;
  },
  classExerciseOverviewSortByAlphabet: State => {
    return State.classExerciseOverviewSortByAlphabet;
  },
  classExerciseOverviewSortOrderAscending: State => {
    return State.classExerciseOverviewSortOrderAscending;
  },
  // childQuestions: State => {
  //   return State.childQuestions;
  // },
  // selectedChildQuestionId: State => {
  //   return State.selectedChildQuestionId;
  // },
  // selectedChildQuestion: State => {
  //   if (!State.childQuestions)
  //     return null;

  //   return State.childQuestions[State.selectedChildQuestionId];
  // }
};

const actions: ActionTree<Globals, RootStore> = {
  async SelectSchoolClass({ commit, dispatch, getters }, schoolClass: SchoolClassVM | null) {
    await commit("setSchoolClass", schoolClass); // Call mutations with commit
    // Get pupils
    let pupils: PortalUserVM[] = [];
    if (schoolClass)
      pupils = await rest.url("teacher/getPupils").query({ schoolClassId: schoolClass.id }, true).get();

    await commit("setPupilsOfClass", pupils); // Call mutations with commit

    //await dispatch("SelectSchoolClass", schoolClass); // Call actions with dispatch
  }
};

const mutations: MutationTree<Globals> = {
  setPupil: (State, pupil: PortalUserVM) => {
    State.selectedPupil = pupil;
  },
  setSchoolClass: (State, schoolClass: SchoolClassVM) => {
    State.selectedSchoolClass = schoolClass;
  },
  setPupilsOfClass: (State, pupils: PortalUserVM[]) => {
    State.pupilsOfSelectedClass = pupils;
  },
  setSubject: (State, subject: ExSubject) => {
    State.selectedSubject = subject;
  },
  setExBundleAssignment: (State, exBundleAssignment: ExBundleAssignmentVM) => {
    State.selectedExBundleAssignment = exBundleAssignment;
  },
  setExercise: (State, exercise: ExerciseVM) => {
    State.selectedExercise = exercise;
  },
  showPrintPupilsQrCode: State => {
    State.showPrintPupilsQrCode = !State.showPrintPupilsQrCode;
    State.isInPrintMode = true;
  },
  setViewToPrintMode: (State, enablePrintView: boolean) => {
    State.isInPrintMode = enablePrintView;
  },
  setClassExerciseOverviewSortByAlphabet: (State, value: boolean) => {
    State.classExerciseOverviewSortByAlphabet = value;
  },
  setClassExerciseOverviewSortOrderAscending: (State, value: boolean) => {
    State.classExerciseOverviewSortOrderAscending = value;
  },
  // setChildQuestions: (State, value: ExQuestion[] | null) => {
  //   State.childQuestions = value;
  // },
  // setSelectedChildQuestionId: (State, value: number) => {
  //   State.selectedChildQuestionId = value;
  // },
  // setSelectedChildQuestion: (State, value: ExQuestion) => {
  //   if (!State.childQuestions)
  //     return;

  //   State.childQuestions[State.selectedChildQuestionId] = value;
  // }
};

const namespaced: boolean = true;

export const globals: Module<Globals, RootStore> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
