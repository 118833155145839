var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.isMultipleChoice)?_c('v-sheet',{staticClass:"dc-mpc rounded-xl my-2",style:(_vm.borderStyle),attrs:{"color":_vm.backgroundColor},on:{"click":function($event){$event.stopPropagation();return _vm.onItemClicked.apply(null, arguments)}}},[_c('v-list-item',{attrs:{"color":"transparent"}},[_c('v-list-item-action',{staticClass:"my-0"},[(!_vm.finalized && !_vm.isChecked)?_c('v-icon',{attrs:{"color":_vm.checkBoxColor,"size":"30"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),(!_vm.finalized && _vm.isChecked)?_c('v-icon',{attrs:{"color":_vm.checkBoxColor,"size":"30"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e(),(_vm.finalized)?[(_vm.selectedChoice && _vm.selectedChoice.isRight)?[(_vm.isChecked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.$store.state.ux.colorHeader1,"size":"30"}},on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,false,1002561292)},[_vm._v(" Es war richtig diese Antwort auszuwählen "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-emoticon-happy")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30"}},on),[_vm._v("mdi-checkbox-blank-circle-outline")])]}}],null,false,1566703461)},[_vm._v(" Es war richtig diese Antwort nicht auszuwählen "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-emoticon-happy")])],1)]:_vm._e(),(_vm.selectedChoice && !_vm.selectedChoice.isRight)?[(_vm.isChecked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.$store.state.ux.colorError,"size":"30"}},on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,false,2078400282)},[_vm._v(" Es war falsch diese Antwort auszuwählen "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-emoticon-sad")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.$store.state.ux.colorError,"size":"30"}},on),[_vm._v("mdi-checkbox-blank-circle-outline")])]}}],null,false,2961480068)},[_vm._v(" Es war falsch diese Antwort nicht auszuwählen "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-emoticon-sad")])],1)]:_vm._e()]:_vm._e()],2),_c('v-list-item-content',{staticStyle:{"text-align":"start"}},[_c('span',{style:({ fontSize: _vm.fontSize, lineHeight: _vm.lineHeight, color: _vm.color, fontWeight: _vm.weight, fontStyle: _vm.fontStyle}),domProps:{"innerHTML":_vm._s(_vm.hyperText)}})])],1)],1):[(!_vm.finalized)?_c('v-select',{staticClass:"fit ma-1",attrs:{"items":_vm.choiceList.items,"item-text":"label","append-icon":"mdi-gesture-tap","return-object":"","hide-details":"","dense":"","solo":""},on:{"change":_vm.onChanged},model:{value:(_vm.selectedChoice),callback:function ($$v) {_vm.selectedChoice=$$v},expression:"selectedChoice"}}):_vm._e(),(_vm.finalized && _vm.selectedChoice && _vm.selectedChoice.isRight)?_c('v-sheet',{staticClass:"d-inline-block rounded noselect dc-textbox pa-1 ma-1",attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.selectedChoice.label))]):_vm._e(),(_vm.finalized && _vm.selectedChoice && !_vm.selectedChoice.isRight)?_c('v-sheet',{staticClass:"d-inline-block rounded noselect dc-textbox pa-1 ma-1",attrs:{"color":"error"}},[_c('del',[_vm._v(_vm._s(_vm.selectedChoice.label))])]):_vm._e(),(_vm.finalized && _vm.selectedChoice && !_vm.selectedChoice.isRight)?_c('v-sheet',{staticClass:"d-inline-block rounded noselect dc-textbox pa-1 ma-1",attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.rightAnswer))]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }