



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
//import Login_old from '@/components/Login_old.vue'; // @ is an alias to /src
import Login from '@/components/Login.vue'; // @ is an alias to /src
import MyProfile from  '@/components/MyProfile.vue';

const auth = namespace('auth');

@Component({
  components: {
      Login,
      MyProfile
  }
})
export default class AvatarMenu extends Vue {
    @auth.Action Logout: any;
    @auth.Getter loginName!: string;

    showMenu: boolean = false;
    showLoginDialog: boolean = false;
    showRegisterDlg: boolean = false;
    showProfileDlg: boolean = false;

    openLoginDialog() {
      // value is use as trigger => just invert it
      this.showLoginDialog = !this.showLoginDialog;
    }

    async onLogout() {
        await this.Logout();
    }

    goToRegistration() {
      this.showLoginDialog = false;
      this.showRegisterDlg = true;
    }
}
