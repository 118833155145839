


































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import AssignmentMenu from '../components/ExerciseAssignment/AssignmentMenu.vue';

// Interfaces
import { ExDataSet, ExChoice, ExQuestion } from '../interfaces/ExDataSet';
import { ExerciseVM } from '../interfaces/ExerciseVM';
// import { ExBundleVM, ExSubject } from '@/interfaces/ExBundleVM';
import de from 'vuetify/src/locale/de';
import { ParamBool } from "@/interfaces/ParamBool";
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentGroupVM";
import { SchoolClassVM } from "@/interfaces/SchoolClassVM";
import { PortalUserVM } from "@/interfaces/PortalUserVM";
import { ExSubject } from "@/interfaces/ExBundleVM";

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    AssignmentMenu
  }
})
export default class ExerciseAssignment extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedPupil!: PortalUserVM;
  @globals.Getter selectedSubject!: ExSubject;
  @globals.Action SelectSchoolClass: any;
  @auth.Getter isAdmin: any;
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  showResetResultsConfDialog = false;
  showDeleteAssignmentDialog = false;
  selectedAssignment:ExBundleAssignmentVM | null = null;
  classes: SchoolClassVM[] | null = null;
  selectedClassId: number = 0;
  // pupils: PortalUserVM[] | null = null;
  // selectedPupilId: string | null = null;
  subjects: ExSubject[] = [];
  exBundleGroups: ExBundleAssignmentGroupVM[] = [];
  grade: number = 1;


  bundleAssignments: ExBundleAssignmentVM[] | null = [];
  subjectParam: ParamBool = { value: true };

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Übungsauswahl', disabled: true, href: '/exerciseAssignment' },
  ];

  async mounted() {
    this.$store.commit("globals/setPupil", null);
    await this.getClasses();
    await this.getSubjects();

    if (this.selectedClass) {
      // this.selectedClassId = this.selectedClass?.id ?? 0;
      // console.log("set grade: ", this.selectedClass.grade);
      this.grade = this.selectedClass.grade;
      this.getAssignmentsForAllExBundles();
    } else {
      this.selectedClassId = 0;
      this.grade = 1;
      this.getAssignmentsForAllExBundles();
    }
  }

  updateView() {
    this.getAssignmentsForAllExBundles();
  }

  async getClasses() {
    this.classes = await rest.url("teacher/getMyClasses").get();
    if (this.classes == undefined || this.classes.length == 0) {
      this.selectedClassId = 0;
      await this.SelectSchoolClass(null);
      // console.log("no class selected");
      return;
    }

    // Try to select last selected class
    if (this.classes.length > 0 && this.selectedClass != null) {
      let lastSelectedClass = this.classes.find(c => c.id == this.selectedClass.id);
      if (lastSelectedClass != null) {
        this.selectedClassId = lastSelectedClass.id;
        await this.SelectSchoolClass(lastSelectedClass);
        // console.log("last class selected");
      }
    }

    // Select first class
    if (this.selectedClassId == 0) {
      this.selectedClassId = this.classes[0].id;
      await this.SelectSchoolClass(this.classes[0]); // Call actions
      // console.log("first class selected");
      return;
    }
  }

  async getSubjects() {
    // this.subjects = await rest.url("exercises/getSubjects").query({ selectAll: true }, true).get();
    this.subjects = await rest.url("exercises/getSubjects").get();
    if (this.selectedSubject != null && this.selectedSubject.id != 0) {
      let selectedSubject = this.subjects.find(s => s.id == this.selectedSubject.id);
      this.$store.commit("globals/setSubject", selectedSubject);
    } else {
      this.$store.commit("globals/setSubject", this.subjects[0]);
    }
  }

  async onClassChanged() {
    let selectedSchoolClass = this.classes!.find(c => c.id == this.selectedClassId);
    // this.$store.commit("globals/setSchoolClass", selectedSchoolClass);
    await this.SelectSchoolClass(selectedSchoolClass); // Call actions with dispatch
    this.grade = this.selectedClass.grade;
    this.getAssignmentsForAllExBundles();
  }

  async changeSubject(subject: ExSubject) {
    // console.log("grade changed, get exercises")
    this.$store.commit("globals/setSubject", subject);
    this.getAssignmentsForAllExBundles();
  }

  async changeGrade(grade: number) {
    // console.log("grade changed, get exercises")
    this.grade = grade;
    this.getAssignmentsForAllExBundles();
  }

  async getAssignmentsForAllExBundles() {
    // let pupilId: string | null = null;
    // if (this.selectedPupil != null)
    //     pupilId = this.selectedPupil.id;

    let param: ParamGetExercises = {
      grade: this.grade,
      subjectId: this.selectedSubject.id,
      selectedSchoolClassId: this.selectedClass?.id ?? 0,
      selectedPupilId: null,
      bundleId: 0
    };
    this.exBundleGroups = await rest.url("teacher/getAssignmentsForAllExBundles").post(param);
  }

  showExerciseBundle(assignment: ExBundleAssignmentVM) {
    this.$store.commit('globals/setExBundleAssignment', assignment);
    router.push("/exerciseBundle");
  }

  openResetResultsConfDialog(assignment: ExBundleAssignmentVM) {
    this.selectedAssignment = assignment;
    this.showResetResultsConfDialog = true;
  }

  async resetResults() {
    await rest.url("teacher/resetResults").post(this.selectedAssignment);
    this.showResetResultsConfDialog = false;
  }

  openDeleteAssignmentDialog(assignment: ExBundleAssignmentVM) {
    this.selectedAssignment = assignment;
    this.showDeleteAssignmentDialog = true;
  }

  async deleteAssignments() {
    await rest.url("admin/deleteAllAssignmentsToClass").post(this.selectedAssignment);
    this.getAssignmentsForAllExBundles();
    this.showDeleteAssignmentDialog = false;
  }

  goBack() {
    router.go(-1);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
