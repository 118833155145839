

































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { LogEntry } from "@/interfaces/LogEntry";

@Component
export default class Logging extends Vue {
  logs: LogEntry[] = [];

  // $refs!: {};

  headers = [
    { text: "Zeit",value: "time",sortable: true },
    { text: "Anwender", value: "user",sortable: true },
    { text: "Meldung", align: "left", value: "message", sortable: true },
    { text: "Details", align: "left", value: "messageDetails",sortable: true },
    { text: "Context", value: "context",sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Aktionen", value: "", sortable: false }
  ];

  mounted() {
    this.updateLogging();
  }

  async updateLogging() {
    this.logs = await rest.url("admin/getLogEntries").post();
    // console.log("users:\r\n" + this.users);
  }
}
