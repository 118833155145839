














































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
import de from "vuetify/src/locale/de";

const globals = namespace("globals");

@Component({
  components: {
    qrcode: VueQrcode
  }
})
export default class PupilQrCodeCard extends Vue {
  @Prop() pupil!: PortalUserVM;

  @globals.Getter selectedClass!: SchoolClassVM;

  // $refs!: {};

  mounted() {}
}
