


















































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import SetAvatar from "@/components/SetAvatar.vue"; // @ is an alias to /src
import {
  PortalUserVM,
  SelectListItem,
  SchoolVM,
  SchoolClassVM
} from "@/interfaces/PortalUserVM";
// import { ImageWrapper } from "@/interfaces/ImageWrapper";
// import { VRowExpandTransition } from "vuetify/lib";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const auth = namespace("auth");

@Component({
  components: {
    SetAvatar
  }
})
export default class MyProfile extends Vue {
  @Prop() showDialog!: boolean;
  localShowDialog:boolean = false;

  defaultUser = <PortalUserVM>{};

  editUser: PortalUserVM = _.cloneDeep(this.defaultUser);
  userFormValid = true;

  $refs!: {
    userForm: HTMLFormElement;
  };

  @auth.Getter isAdmin: any;
  @auth.Getter isPrincipal: any;
  @auth.Getter isTeacher: any;
  @auth.Getter isLearner: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  mounted() {
    // Make sure that the showDialog variable of the parent is in sync upon initialisation
    this.$emit('update:showDialog', false);
  }

  // beforeDestroy() {
  //   debugger
  //   clearAllBodyScrollLocks();
  //   this.$emit('update:showDialog', false);
  // }

  get disableEditName() {
    if (this.isAdmin || this.isPrincipal || this.isTeacher) return false;
    return true;
  }

  // onVisibilityChanged(visible: boolean) {
  //   // if (this.$refs.userForm != undefined) this.$refs.userForm.reset();

  //   this.$emit("visibilityChanged:myProfile", visible);
  // }

  @Watch("showDialog")
  onShowDialogChanged(val: boolean) {
    if (val == true) {
      // Dialog opened
      this.editUser = _.cloneDeep(this.$store.state.auth.portalUser);
      // const modal = document.querySelector('.modal');
      // disableBodyScroll(modal);
      // document.getElementsByTagName("body")[0].className="noscroll";
      this.localShowDialog = true;

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
      // document.body.removeAttribute("class","noscroll");
      this.localShowDialog = false;
    }
  }

  onAvatarChanged(avatar: string) {
    this.editUser.avatar = avatar;
  }

  onCancel() {
    // if (this.$refs.userForm != undefined) {
    //   this.$refs.userForm.reset();
    // }
    // this.$emit("visibilityChanged:myProfile", false);
    this.$emit('update:showDialog', false);
  }

  // removeImage() {
  //   this.editUser.avatar = "";
  // }

  async saveUserInfo() {
    if (!this.$refs.userForm.validate()) return;
    await rest.url("auth/setPortalUser").post(this.editUser);
    await this.$store.commit("auth/setPortalUser", this.editUser);
    // this.$emit("visibilityChanged:myProfile", false);
    this.$emit('update:showDialog', false);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Rules

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz."
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => v.length == 0 || v.length > 7 || "Schlüssel zu kurz."
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];
}
