


















































































































































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';
// interfaces
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
// import { School } from "@/interfaces/School";
import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const auth = namespace("auth");

@Component({
  components: {}
})
export default class SchoolsView extends Vue {
  showNewSchoolDialog = false;
  showEditSchoolDialog = false;
  showDeleteSchoolDialog = false;
  selectedSchool: School | null = null;
  defaultSchool: School = {
    id: 0,
    name: "",
    street: "",
    zip: "",
    city: "",
    portalUsers: [] as PortalUser[],
    schoolClasses: [] as SchoolClass[],
  };
  newSchool: School = _.cloneDeep(this.defaultSchool);
  editSchool: School =  _.cloneDeep(this.defaultSchool);
  newSchoolFormValid: boolean = true;
  schools: School[] = [];


  showNewTeacherDialog = false;
  showEditTeacherDialog = false;
  showDeleteTeacherDialog = false;
  selectedTeacherIdx: number = -1;
  defaultUser: PortalUserVM = {
    id: "",
    firstName: "",
    lastName: "",
    nickname: "",
    fullName: "",
    email: "",
    userName: "",
    disabled: false,
    selectRoles: [] as SelectListItem[],
    roles: [],
    showMenu: false,
    selected: false,
    avatar: "",
    disableAvatar: false,
    gdprApprovalDate: null,
    gdprApprovalRequired: false,
    activationKey: "",
    schoolId: 0,
    schoolClassId: 0,
    schools: [] as SchoolVM[],
    classLeaderOf: [] as SchoolClassVM[],
    schoolClasses: [] as SchoolClassVM[]
  };
  editTeacher: PortalUserVM =  _.cloneDeep(this.defaultUser);
  deleteTeacher: PortalUserVM =  _.cloneDeep(this.defaultUser);
  newTeacherFormValid: boolean = true;
  editTeacherFormValid: boolean = true;
  users: PortalUserVM[] = [];

  // Principals
  // showNewPrincipalDialog = false;
  // showEditPrincipalDialog = false;
  // showDeletePrincipalDialog = false;


  $refs!: {
    addNewSchoolForm: HTMLFormElement;
    addNewTeacherForm: HTMLFormElement;
  };

  @auth.Getter isAdmin: any;
  @auth.Getter isPrincipal: any;
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;

  mounted() {
    this.updateSchoolList();
  }

  @Watch("showNewSchoolDialog")
  onShowNewSchoolDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }
  @Watch("showEditSchoolDialog")
  onShowEditSchoolDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  @Watch("showNewTeacherDialog")
  onShowNewTeacherDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  @Watch("showEditTeacherDialog")
  onShowEditTeacherDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async updateSchoolList() {
    this.schools = await rest.url("admin/getSchools").get();
    // console.log("users:\r\n" + this.users);
  }

  // --- School Functions ---
  onEditSchool() {
    if (this.selectedSchool == null)
      return;

    this.editSchool = _.cloneDeep(this.selectedSchool);
    this.showEditSchoolDialog = true;
  }

  onShowDeleteSchoolDialog() {
    if (this.selectedSchool == null)
      return;
    this.showDeleteSchoolDialog = true;
  }

  async onDeleteSchool() {
    if (this.selectedSchool == null)
      return;

    const index = this.schools.indexOf(this.selectedSchool);
    await rest.url("admin/deleteSchool").post(this.selectedSchool);
    this.schools.splice(index, 1);
    this.selectedSchool = null;
    this.showDeleteSchoolDialog = false;
  }

  closeEditSchoolDialog() {
    this.showEditSchoolDialog = false;
    // setTimeout(() => {
    //   this.editSchool = _.cloneDeep(this.defaultSchool);
    // }, 300);
  }

  async saveEditSchoolDialog() {
    if (this.selectedSchool != null) {
      Object.assign(this.selectedSchool, this.editSchool);

      await rest.url("admin/saveSchool").post(this.editSchool);
    }
    this.closeEditSchoolDialog();
  }

  openNewSchoolDialog() {
    this.newSchool = _.cloneDeep(this.defaultSchool);
    this.showNewSchoolDialog = true;
  }

  closeNewSchoolDialog() {
    this.showNewSchoolDialog = false;

    // setTimeout(() => {
    //   // this.selectedSchool = Object.assign({}, this.defaultUser);
    //   this.newSchool = _.cloneDeep(this.defaultSchool);
    // }, 300);
  }

  async saveNewSchoolDialog() {
    if (!this.$refs.addNewSchoolForm.validate()) return;

    await rest.url("admin/addSchool").post(this.newSchool);
    this.updateSchoolList();
    this.closeNewSchoolDialog();
  }

  async onSchoolChanged() {
    await this.updateTeacherList();
  }

  // --- Teacher Functions ---
  // hasPrincipalRole(u: PortalUserVM): boolean {
  //   return new List(u.roles ?? {} as string[]).contains("Principal");
  // }

  async updateTeacherList() {
    if (this.selectedSchool == null)
      return;

    this.users = await rest.url("admin/getTeachers").post(this.selectedSchool);
  }

  get principals() {
    return new List(this.users).where(u => (new List(u.roles!)).contains("Principal")).toArray();
  }

  get teachers() {
    return new List(this.users).where(u => !(new List(u.roles!)).contains("Principal")).toArray();
  }

  onEditTeacher(teacher: PortalUserVM) {
    this.selectedTeacherIdx = this.users.indexOf(teacher);
    this.editTeacher = Object.assign({}, teacher);
    this.showEditTeacherDialog = true;
  }

  onShowDeleteTeacherDialog(teacher: PortalUserVM){
    this.deleteTeacher = teacher;
    this.deleteTeacher.schoolId = this.selectedSchool!.id;
    this.showDeleteTeacherDialog = true;
  }

  async onDeleteTeacher(teacher: PortalUserVM) {
    await rest.url("admin/deleteTeacherFromSchool").post(this.deleteTeacher);
    const index = this.users.indexOf(this.deleteTeacher);
    this.users.splice(index, 1);
    this.showDeleteTeacherDialog = false;
  }

  closeEditTeacherDialog() {
    this.showEditTeacherDialog = false;
    // setTimeout(() => {
    //   this.editTeacher = _.cloneDeep(this.defaultUser);
    // }, 300);
  }

  async saveEditTeacherDialog() {
    if (this.selectedTeacherIdx > -1) {
      Object.assign(this.users[this.selectedTeacherIdx], this.editTeacher);
      this.updateFullName(this.users[this.selectedTeacherIdx]);
      await rest.url("admin/saveTeacher").post(this.editTeacher);
    }

    this.closeEditTeacherDialog();
  }

  updateFullName(user: PortalUserVM) {
      if (user.lastName != null) {
        user.fullName = user.lastName;
      }
      if (user.lastName != null && user.firstName != null) {
        user.fullName += ", ";
      }
      if (user.firstName != null) {
        user.fullName += user.firstName;
      }
  }

  openNewTeacherDialog() {
    this.editTeacher = _.cloneDeep(this.defaultUser);
    this.createNewKey();
    this.showNewTeacherDialog = true;
  }

  createNewKey() {
    this.editTeacher.activationKey = "";
    for (let i = 0; i <= 7; i++) {
      this.editTeacher.activationKey += Math.floor(Math.random() * 10);
    }
  }

  closeNewTeacherDialog() {
    this.showNewTeacherDialog = false;

    // setTimeout(() => {
    //   // this.selectedSchool = Object.assign({}, this.defaultUser);
    //   this.editTeacher = _.cloneDeep(this.defaultUser);
    // }, 300);
  }

  async saveNewTeacher() {
    if (!this.$refs.addNewTeacherForm.validate())
      return;

    if (this.selectedSchool == null)
      return;

    this.updateFullName(this.editTeacher);
    this.editTeacher.schoolId = this.selectedSchool.id;
    // this.editTeacher.userName = this.editTeacher.email;
    await rest.url("admin/addTeacher").post(this.editTeacher);
    this.updateTeacherList();
    this.closeNewTeacherDialog();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Validation

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  mailEditRules = [
    (v: string) =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz"
  ];

  schoolNameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 3) || "Name zu kurz"
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
  ];

  keyEditRules = [
    (v: string) =>
      !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz"
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
  ];
}
