






























































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import UserManager from "@/components/Admin/UserManager.vue"; // @ is an alias to /src
import Logging from "@/components/Admin/Logging.vue"; // @ is an alias to /src
import { ServiceStatusVM } from "@/interfaces/ServiceStatusVM";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

@Component({
  components: {
    UserManager,
    Logging
  }
})
export default class Admin extends Vue {
  selectedView: string = "userAdmin";
  serviceStatus: ServiceStatusVM | null = null;
  sqlZipLoading = false;
  paramDir: ParamDictionary = { dictionary: {}};

  mounted() {
    // this.getServiceStatus();
  }

  async onCreateSqlZipFile() {
    this.sqlZipLoading = true;
    // this.paramDir.dictionary = {};
    // this.paramDir.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    await rest.url("admin/createSqlZipFile")
      .get()
      .then((fileName) => {
        this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
      })
      .finally(() => {
        this.sqlZipLoading = false;
      });
      // let fileName = `${this.grade}.${this.selectedSubject!.name ?? ""}-all.zip`;
  }

  async getServiceStatus() {
    this.serviceStatus = await rest.url("admin/getServiceStatus").get();
    console.log(this.serviceStatus);
  }
}
