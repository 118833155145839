































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { ParamGetPupilResultsForBundle } from "@/interfaces/ParamGetPupilResultsForBundle";
import { PupilExBundleResultVM } from '@/interfaces/PupilExBundleResultVM';

// componennts
// import PupilExBundleStatus from "../components/ExerciseComponents/PupilExBundleStatus.vue";
import ExBundleStatus from "../components/ExerciseComponents/ExBundleStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    // PupilExBundleStatus
    ExBundleStatus
  }
})
export default class ClassExerciseOverview extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  @globals.Getter classExerciseOverviewSortByAlphabet!: boolean;
  @globals.Getter classExerciseOverviewSortOrderAscending!: boolean;
  @auth.Getter isTeacher: any;
  @auth.Getter isParent: any;
  @auth.Getter isPupil: any;
  // sortByAlphabet: boolean = true;
  // sortOrderAscending: boolean = true;

  // classes: SchoolClassVM[] = null;
  // selectedClassId: number = 0;
  // pupils: PortalUserVM[] = null;
  // selectedPupilId: string = null;
  // subject: ExSubject = null;
  // subjects: ExSubject[] = [];
  // exBundles: ExBundleVM[] = [];
  // subjectParam: ParamBool = { value: true };
  // selectAll: boolean = true;
  bundleAssignments: ExBundleAssignmentVM[] = [];

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Übungen', disabled: true, href: '/exercises' },
 ];

  async mounted() {
    if (this.selectedClass == null || this.selectedExBundleAssignment == null) {
      router.push("/");
      return;
    }

    // this.$store.commit("globals/setSubject", null); // On open show all subjects
    // await this.getSubjects();
    this.getExBundels();
  }

  async getExBundels() {
    if (!this.selectedExBundleAssignment || !this.selectedExBundleAssignment.exBundle)
      return;

    // let pupilId: string = null;
    // if (this.selectedPupil != null)
    //   pupilId = this.selectedPupil.id;

    let param: ParamGetPupilResultsForBundle = {
      selectedSchoolClassId: this.selectedClass.id,
      selectedBundleId: this.selectedExBundleAssignment.exBundle.id,
      sortByAlphabet: this.classExerciseOverviewSortByAlphabet,
      sortOrderAscending: this.classExerciseOverviewSortOrderAscending
    };

    this.bundleAssignments = await rest.url("teacher/getPupilResultsForBundle").post(param);
  }

  onSort() {
    if (this.classExerciseOverviewSortByAlphabet && this.classExerciseOverviewSortOrderAscending) {
      this.$store.commit("globals/setClassExerciseOverviewSortOrderAscending", false);
    } else if (this.classExerciseOverviewSortByAlphabet && !this.classExerciseOverviewSortOrderAscending) {
      this.$store.commit("globals/setClassExerciseOverviewSortByAlphabet", false);
      this.$store.commit("globals/setClassExerciseOverviewSortOrderAscending", true);
    } else if (!this.classExerciseOverviewSortByAlphabet && this.classExerciseOverviewSortOrderAscending) {
      this.$store.commit("globals/setClassExerciseOverviewSortOrderAscending", false);
    } else if (!this.classExerciseOverviewSortByAlphabet && !this.classExerciseOverviewSortOrderAscending) {
      this.$store.commit("globals/setClassExerciseOverviewSortByAlphabet", true);
      this.$store.commit("globals/setClassExerciseOverviewSortOrderAscending", true);
    }

    this.getExBundels();
  }

  async showExerciseBundle(bundleAssignmentVm: ExBundleAssignmentVM) {
    this.$store.commit("globals/setPupil", bundleAssignmentVm.pupil);
    this.$store.commit('globals/setExBundleAssignment', bundleAssignmentVm);

    router.push("/exerciseBundle");
  }

  goBack() {
    router.go(-1);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
