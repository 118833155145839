





































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';
import { ExChoiceListItem, ExChoiceList } from '@/interfaces/ExDataSet';

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class CompSelect extends Vue {
  @Prop() choiceList!: ExChoiceList;
  @Prop() finalized!: boolean;
  @Prop() preset!: boolean;
  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() size!: string; // 24, evtl.: x-small, small, medium, large, x-large
  @Prop() color!: string;
  @Prop() weight!: string; // lighter, normal, bold, bolder, 100,200,300,400,500,600,700,800,900
  @Prop() fontStyle!: string; // normal, italic, oblique

  isMultipleChoice = false;
  isChecked = false;
  selectedChoice: ExChoiceListItem | null = null;
  showSuccessCheck: boolean = false;
  showFailedCheck: boolean = false;
  rightAnswer: string | null = null;

  // $refs!: {};

  mounted() {
    if (this.choiceList.items) {
      this.rightAnswer = new List(this.choiceList.items).singleOrDefault(x => x.isRight)?.label ?? null;
      if (this.preset) {
        this.selectedChoice = new List(this.choiceList.items).firstOrDefault() ?? null;
        // this.$emit("changed:CompSelect", this.choiceList.id, this.selectedChoice!.isRight);
        this.$store.commit("quizplayerstore/setChoiceState", { id: this.choiceList.id, isRight: this.selectedChoice!.isRight });
      }
      if (this.choiceList.items.length == 2 && new List(this.choiceList.items).where(x => x.mdiIcon == "dc-mpc").count() == 2)
        this.isMultipleChoice = true;
    }
  }

  onChanged() {
    if (!this.selectedChoice)
      return;

    // console.log("emit change event");
    // this.$emit("changed:CompSelect", this.choiceList.id, this.selectedChoice.isRight);
    this.$store.commit("quizplayerstore/setChoiceState", { id: this.choiceList.id, isRight: this.selectedChoice.isRight });
  }

  // onCheckBoxChanged(e: Event) {
  //   // console.log(this.isChecked);
  //   e.stopPropagation();
  //   this.selectedChoice = new List(this.choiceList.items!).singleOrDefault(x => x.label == (this.isChecked ? "true" : "false"))!;
  //   this.$store.commit("quizplayerstore/setChoiceState", { id: this.choiceList.id, isRight: this.selectedChoice.isRight });
  // }

  onItemClicked() {
    if (this.finalized)
      return;
    this.isChecked = !this.isChecked;
    this.selectedChoice = new List(this.choiceList.items!).singleOrDefault(x => x.label == (this.isChecked ? "true" : "false"))!;
    this.$store.commit("quizplayerstore/setChoiceState", { id: this.choiceList.id, isRight: this.selectedChoice.isRight });
  }

  get fontSize() {
    if (this.size == null)
      return "18px";
    if (isNaN(Number(this.size))) // Number(this.size) != NaN)
      return this.size;
    return this.size + "px";
  }

  get lineHeight() {
    let fs = this.fontSize.replace("px", "");
    if (isNaN(Number(fs)))
      return this.fontSize;

    return Number(fs) + 2 + "px";
  }

  get hyperText() {
    if (this.text != null)
      return this.text;
    if (this.textArray != null)
      return this.textArray.join('');

    return null;
  }

  get backgroundColor() {
    if (this.$vuetify.theme.dark) {
      return "#ffffff30";

      // if (!this.finalized)
      //   return "#ffffff30";
      // if (this.selectedChoice?.isRight)
      //   return "#00ff0020";
      // return "#ff000025";
    }

    return "#ffffff80";

    // if (!this.finalized)
    //   return "#ffffff80";
    // if (this.selectedChoice?.isRight)
    //   return "#00ff0020";
    // return "#ff000025";
  }

  get borderStyle() {
    if (!this.finalized)
      return "";
    if (!this.selectedChoice?.isRight)
      return "border: 2px solid #f4433680;";
    if (this.selectedChoice?.isRight && this.isChecked)
      // return "border: 2px solid #37BBA980";
      return `border: 2px solid ${this.$store.state.ux.colorHeader1}80`
    return "";
  }

  get checkBoxColor() {
    if (this.$vuetify.theme.dark)
      return "#ffffff";

    return "#000000a0";
  }
}
