





















































import _ from "lodash";
// import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class ExStatus extends Vue {
    @Prop() result!: number;
    @Prop() isTest!: boolean;
    @Prop() level!: number;
    @Prop() maxLevel!: number;
    @Prop() snooze!: boolean;
    @Prop() finished!: boolean;
    // @Prop() isTeacher!: boolean;

    // get value() {
    //     // Do rounding
    //     if (this.result < 99)
    //         return (this.result + 1) * 3 / 100;
    //     else
    //         return this.result * 3 / 100;
    // }
    // $refs!: {};

    mounted() {}
}
