










































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from "linq-collections";
import CompText from "./CompText.vue";
import CompBlock from "./CompBlock.vue";
import Linebreak from "./CompLinebreak.vue";
import CompImage from "./CompImage.vue";
import CompHtml from "./CompHtml.vue";
import CompSpeak from "./CompSpeak.vue";
import dchtmlelement from "./dchtmlelement.vue";
import CompSelect from "./CompSelect.vue";
import CompRight from "./CompRight.vue";
import CompWrong from "./CompWrong.vue";

// interfaces
import {
  ExDataSet,
  ExViewElement,
  ExChoiceList,
  ExChoiceListItem,
  ExChoice,
  ExQuestion,
} from "../../interfaces/ExDataSet";
import { ExerciseVM } from "../../interfaces/ExerciseVM";

const globals = namespace("globals");
const quizplayerstore = namespace("quizplayerstore");
// const auth = namespace("auth");

@Component({
  components: {
    CompText,
    CompBlock,
    Linebreak,
    CompImage,
    CompHtml,
    CompSpeak,
    dchtmlelement,
    CompSelect,
    CompRight,
    CompWrong,
  },
})
export default class CompMultipleSteps extends Vue {
  @globals.Getter selectedExercise!: ExerciseVM;
  @quizplayerstore.Getter childQuestions!: ExQuestion[];
  @quizplayerstore.Getter selectedChildQuestionId!: number;
  @quizplayerstore.Getter selectedChildQuestion!: ExQuestion;
  @Prop() alignment!: string;
  @Prop() finalized!: boolean;
  @Prop() nextStep!: boolean;
  selectLists: ExChoiceList[] = [];
  // $refs!: {};

  mounted() {
    // this.updateSelectLists();
    // this.setChoiceList();
  }

  @Watch("finalized")
  async onFinalizeChanged(val: boolean) {
    if (val) {
      // await this.delay(500);
      if (!this.selectedChildQuestion) return;

      // console.log("CompMultiplsSteps.onFinalizeChanged");
      this.selectedChildQuestion.isFinalized = true;

      // Show resolution
      // let selectedChoice =  new List(this.selectedChildQuestion.choices).singleOrDefault(c => c.isClicked);
      // if (!selectedChoice)
      //   return;

      // if (this.selectedChildQuestion.isRightAnswered) {
      //   // Right answer
      //   if (this.selectedExercise.alwaysShowResolution && (this.selectedChildQuestion.resolutionViewElements?.length ?? 0 > 0)) {
      //     this.selectedChildQuestion.showResolution = true;

      //   }
      // } else {
      //   // Wrong answer
      //   if ((this.selectedExercise.alwaysShowResolution || this.selectedExercise.showResolutionOnWrongAnswer) && (this.selectedChildQuestion.resolutionViewElements?.length ?? 0) > 0) {
      //     this.selectedChildQuestion.showResolution = true;
      //   }
      // }
    }
  }

  // After using vuex to store choice state, this emit can be removed
  // async onChoiceSelection(id: number, isRight: boolean) {
  //   this.$emit("changed:CompSelect", id, isRight);
  // }

  // async onTextClicked(isRight: boolean) {
  //   this.$emit("clicked:Text", isRight);
  // }

  stepStyle(isFinalized: boolean, isRightAnswered: boolean) {
    if (isFinalized && isRightAnswered) return "background-color: #00ff0015;";
    if (isFinalized && !isRightAnswered) return "background-color: #ff000015;";
    return "";
  }

  get questionsToShow() {
    if (
      this.childQuestions &&
      this.childQuestions.length >= this.selectedChildQuestionId
    )
      return this.childQuestions.slice(0, this.selectedChildQuestionId + 1);
    return [];
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
