import { FirebaseAppSettings, initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq_Lk_fENpzbBqMEepTTeXIFCmY_18QFc",
  authDomain: "digiclass-online.firebaseapp.com",
  projectId: "digiclass-online",
  storageBucket: "digiclass-online.appspot.com",
  messagingSenderId: "890050820516",
  appId: "1:890050820516:web:ce769dae0a1da1af13e931",
  measurementId: "G-L1EXCEM0HT"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig, <FirebaseAppSettings>{})

console.log('firebase app init')
// console.log(app)