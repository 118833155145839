















































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  PortalUserVM,
  SelectListItem,
  SchoolVM,
  SchoolClassVM
} from "@/interfaces/PortalUserVM";
import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";

// import { VRowExpandTransition } from "vuetify/lib"; // ???
import de from "vuetify/src/locale/de";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  components: {}
})
export default class EditSchoolClass extends Vue {
  @Prop() schoolClass!: SchoolClassVM;
  @Prop() showDialog!: boolean;
  @Prop() newClass!: boolean;

  localShowDialog: boolean = false;
  formValid: boolean = true;
  // showAddTeacherDialog: boolean = false;

  withClassLead: string = "true";
  grads: number[] = [1, 2, 3, 4, 5, 6];
  schoolYears: any[] = new Array();
  // classTeachers: PortalUserVM[] = [] as PortalUserVM[];
  availableTeachers: PortalUserVM[] = [] as PortalUserVM[];
  selectedTeacher: PortalUserVM | null = null;
  defaultUser: PortalUserVM = {
    id: "0",
    nickname: "",
    firstName: "",
    lastName: "",
    fullName: "",
    userName: "",
    email: "",
    disabled: false,
    selectRoles: [] as SelectListItem[],
    roles: [],
    showMenu: false,
    selected: false,
    avatar: "",
    disableAvatar: false,
    gdprApprovalDate: null,
    gdprApprovalRequired: false,
    activationKey: "",
    schoolId: 0,
    schoolClassId: 0,
    schools: [] as SchoolVM[],
    classLeaderOf: [] as SchoolClassVM[],
    schoolClasses: [] as SchoolClassVM[]
  };

  // editUser: PortalUserVM = _.cloneDeep(this.defaultUser);

  $refs!: {
    editClassForm: HTMLFormElement;
  };

  async mounted() {
    // Update user info to see if a new school was added since login
    let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    if (portalUser != null)
      await this.$store.commit("auth/setPortalUser", portalUser);

    // Fill school years
    let year = new Date().getFullYear() - 1;
    if (new Date().getMonth() < 7) {
      year--;
    }
    for (let i = 0; i < 3; i++) {
      let y = year + i;
      let y1 = (y + 1) % 100;
      let sy: string = y + "/" + y1;
      this.schoolYears.push({ label: sy, value: y });
    }
  }

  @Watch("showDialog")
  async onShowDialogChanged(val: boolean) {
    // just used as a trigger to toggle dialog visability
    this.localShowDialog = !this.localShowDialog;

    if (this.localShowDialog) {
      // On dialog open
      // For new class select current school year
      if (this.newClass) {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        if (month < 7) {
          year--;
        }
        this.schoolClass.year = year;
      }

      // For new class select first school
      if (this.newClass && ((this.$store.state.auth.portalUser as PortalUserVM).schools?.length ?? 0) > 0) {
        this.schoolClass.schoolId = this.$store.state.auth.portalUser.schools[0].id;
      }

      // Init new class
      if (this.newClass) {
        this.withClassLead = "true";
      }

      // Get teacher list
      this.availableTeachers = await rest
        .url("teacher/getAvailableTeachers")
        .post(this.schoolClass);
      this.selectedTeacher = _.clone(this.defaultUser);

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  get dialogTitle() {
    if (this.newClass) {
      return "Neue Klasse anlegen";
    }
    return "Klasse bearbeiten";
  }

  async onSchoolChanged() {
    // Get teacher list for this school
    this.availableTeachers = await rest
      .url("teacher/getAvailableTeachers")
      .post(this.schoolClass);
  }

  onTeacherChanged() {
    if (this.selectedTeacher == null || this.schoolClass.classTeachers == null)
      return;

    this.schoolClass.classTeachers.push(this.selectedTeacher);

    let index: number = this.availableTeachers.indexOf(this.selectedTeacher);
    this.availableTeachers.splice(index, 1);
    this.selectedTeacher = _.clone(this.defaultUser);
  }

  onRemoveTeacher(teacher: PortalUserVM) {
    if (this.schoolClass.classTeachers == null)
      return;

    let index: number = this.schoolClass.classTeachers.indexOf(teacher);
    this.schoolClass.classTeachers.splice(index, 1);

    this.availableTeachers.push(teacher);
    this.selectedTeacher = _.clone(this.defaultUser);
  }

  closeDialog() {
    this.localShowDialog = false;
    // setTimeout(() => {
    //   this.editSchool = _.cloneDeep(this.defaultSchool);
    // }, 300);
  }

  async saveClass() {
    if (!this.$refs.editClassForm.validate()) return;
    await rest.url("teacher/saveClass").post(this.schoolClass);
    this.$emit("classSaved:EditSchoolClass", true);
    this.closeDialog();
  }

  // openAddTeacherDialog() {
  //   this.showAddTeacherDialog = true;
  // }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz."
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => v.length == 0 || v.length > 7 || "Schlüssel zu kurz."
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];
}
