










import _, { templateSettings } from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompImage extends Vue {
  // @Prop() value!: string;
  @Prop() src!: string;
  @Prop() width!: string;
  @Prop() height!: string;

  // $refs!: {};

  mounted() {}

  get widthInPixel() {
    if (!this.width)
      return null;
    if (this.width.endsWith("px"))
        return this.width;

    return this.width + "px";
  }

  get heightValue() {
    if (!this.height)
      return null;
    if (this.height.endsWith("px"))
      return this.height.substring(0, this.height.length - 2);

    return this.height;
  }
}
