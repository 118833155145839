






























































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";
import SetAvatar from "@/components/SetAvatar.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

// import { VRowExpandTransition } from "vuetify/lib"; // ???
import de from 'vuetify/src/locale/de';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const globals = namespace("globals");

@Component({
  components: {
    qrcode: VueQrcode,
    SetAvatar
  }
})
export default class EditPupil extends Vue {
  @Prop() pupil!: PortalUserVM;
  @Prop() showDialog!: boolean;
  @Prop() newPupil!: boolean;

  @globals.Getter selectedClass!: SchoolClassVM;

  localShowDialog: boolean = false;
  formValid: boolean = true;
  // showAddTeacherDialog: boolean = false;

  withClassLead: string = "true";
  grads: number[] = [1, 2, 3, 4, 5, 6];
  schoolYears: any[] = new Array();
  // classTeachers: PortalUserVM[] = [] as PortalUserVM[];
  availableTeachers: PortalUserVM[] = [] as PortalUserVM[];
  defaultUser: PortalUserVM = {
    id: "0",
    firstName: "",
    lastName: "",
    nickname: "",
    fullName: "",
    userName: "",
    email: "",
    disabled: false,
    selectRoles: [] as SelectListItem[],
    roles: [],
    showMenu: false,
    selected: false,
    avatar: "",
    disableAvatar: false,
    gdprApprovalDate: null,
    gdprApprovalRequired: false,
    activationKey: "",
    schoolId: 0,
    schoolClassId: 0,
    schools: [] as SchoolVM[],
    classLeaderOf: [] as SchoolClassVM[],
    schoolClasses: [] as SchoolClassVM[]
  };

  editUser: PortalUserVM = _.cloneDeep(this.defaultUser);

  $refs!: {
    editPupilForm: HTMLFormElement;
    firstnameTextField: HTMLElement;
  };

  mounted() {
  }

  @Watch("showDialog")
  async onShowDialogChanged(val: boolean) {
    // just used as a trigger to toggle dialog visability
    this.localShowDialog = !this.localShowDialog;

    if (this.localShowDialog) {
      // On dialog open
      // Init new pupil
      if (this.newPupil) {
        this.pupil.schoolClassId  = this.selectedClass.id;
        this.pupil.userName = await rest.url("teacher/getNewPupilUserName").get();
        this.$nextTick(() => this.$refs.firstnameTextField.focus());
      }

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async createNewPupilUserName() {
    this.pupil.userName = await rest.url("teacher/getNewPupilUserName").get();
  }

  onAvatarChanged(avatar: string) {
    this.pupil.avatar = avatar;
  }

  get dialogTitle() {
      if (this.newPupil) {
        return "Neuen Schüler anlegen";
      }
      return  "Schüler bearbeiten";
  }

  closeDialog() {
    this.localShowDialog = false;
    // setTimeout(() => {
    //   this.editSchool = _.cloneDeep(this.defaultSchool);
    // }, 300);
  }

  async savePupil() {
    if (!this.$refs.editPupilForm.validate())
      return;

    this.pupil.userName = this.pupil.userName?.trim() ?? null;
    this.pupil.firstName = this.pupil.firstName?.trim() ?? null;
    this.pupil.lastName = this.pupil.lastName?.trim() ?? null;
    this.pupil.nickname = this.pupil.nickname?.trim() ?? null;
    this.pupil.email = this.pupil.email?.trim() ?? null;

    await rest.url("teacher/savePupil").post(this.pupil);
    this.$emit('pupilSaved:EditPupil', true);
    this.closeDialog();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async copyToClipboard(value: string) {
    await navigator.clipboard.writeText(value);
     this.$store.commit("ux/SB_SUCCESS", {
        message: value + " in Zwischenablage kopiert",
        timeout: 2000
      });
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz."
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => v.length == 0 || v.length > 7 || "Schlüssel zu kurz."
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];
}
