import { Doughnut, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins;
const options = {
  maintainAspectRatio: false,
//   scales: {
//     yAxes: [
//       {
//         ticks: { beginAtZero: true }
//       }
//     ]
//   }
};

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  mounted() {
    // this.addPlugin({
    //     id: 'ChartDataLabels',
    //     beforeInit: function (chart) {
    //     }
    this.renderChart(this.chartData, (this.options = options));
  }
};