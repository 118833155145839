










































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import SendMessage from "@/components/SendMessage.vue";
import EditPupil from "@/components/SchoolClasses/EditPupil.vue";
import ImportPupils from "@/components/SchoolClasses/ImportPupils.vue";
import PupilQrCodeCard from "@/components/SchoolClasses/PupilQrCodeCard.vue";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

const globals = namespace("globals");

@Component({
  components: {
    EditPupil,
    SendMessage,
    ImportPupils,
    PupilQrCodeCard,
  }
})
export default class PupilsView extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Meine Klassen', disabled: false, href: '/schoolClasses' },
    { text: 'Schüler', disabled: true, href: '/pupils' },
 ];

  busy = false;
  showQrDialog = false;
  showMessageDialog = false;
  showConfInfo = false;
  showPrintQrDialog = false;
  showEditPupilDialog = false;
  showDeleteDialog = false;
  showImportPupilsDialog = false;
  newPupil = true;
  hideFromPrint = false;
  pupils: PortalUserVM[] = [];
  defaultUser: PortalUserVM = {
    id: "",
    firstName: "",
    lastName: "",
    nickname: "",
    fullName: "",
    email: "",
    userName: "",
    disabled: false,
    selectRoles: [] as SelectListItem[],
    roles: [],
    showMenu: false,
    selected: false,
    avatar: "",
    disableAvatar: false,
    gdprApprovalDate: null,
    gdprApprovalRequired: false,
    activationKey: "",
    schoolId: 0,
    schoolClassId: 0,
    schools: [] as SchoolVM[],
    classLeaderOf: [] as SchoolClassVM[],
    schoolClasses: [] as SchoolClassVM[]
  };
  selectedPupil: PortalUserVM = _.cloneDeep(this.defaultUser);
  selectedQrCode: PortalUserVM | null = null;

  mounted() {
    this.updatePupilList();
    if (this.selectedClass == null) {
      router.push("/");
    }
  }

  goBack() {
    router.go(-1);
  }
  // --- Pupil Functions ---
  async updatePupilList() {
    if (this.selectedClass == null)
      return;

    this.pupils = await rest.url("teacher/getPupils").query({ schoolClassId: this.selectedClass.id }, true).get();
  }

  showClassPerformanceOverview() {
    router.push("/classPerformanceOverview");
  }

  onAddPupil() {
    this.newPupil = true;
    this.selectedPupil = _.cloneDeep(this.defaultUser);
    this.showEditPupilDialog = !this.showEditPupilDialog; // Always toggle to trigger watch in component
  }

  // onExerciseCatalog(pupil: PortalUserVM) {
  //   if (pupil == null)
  //     return;

  //   this.$store.commit('globals/setPupil', pupil);
  //   router.push("/exerciseCatalog");
  // }

  onPupilPerformanceOverview(pupil: PortalUserVM) {
    if (pupil == null)
      return;

    this.$store.commit('globals/setPupil', pupil);
    router.push("/pupilPerformanceOverview");
  }

  onNewMessage(pupil: PortalUserVM) {
    if (pupil == null)
      return;

    this.selectedPupil = pupil;
    this.showMessageDialog = true;
  }

  onEditPupil(pupil: PortalUserVM) {
    this.newPupil = false;
    this.selectedPupil = _.cloneDeep(pupil);
    this.selectedPupil.schoolClassId =  this.selectedClass.id;
    this.showEditPupilDialog = !this.showEditPupilDialog; // Always toggle to trigger watch in component
  }

  showDeletePupilConfirmationDlg(pupil: PortalUserVM) {
    if (pupil == null)
      return;

    pupil.schoolClassId = this.selectedClass.id;
    this.selectedPupil = pupil;
    this.showDeleteDialog = true;
  }

  async onDeletePupil() {
    if (this.selectedPupil == null)
      return;

    await rest.url("teacher/deletePupilFromClass").post(this.selectedPupil);
    const index = this.pupils.indexOf(this.selectedPupil);
    this.pupils.splice(index, 1);
    this.showDeleteDialog = false;
  }

  onImportClass() {
    this.showImportPupilsDialog = true;
  }
}
