import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/src/locale/de";
import colors from 'vuetify/lib/util/colors';

import { createSimpleTransition } from "vuetify/lib/components/transitions/createTransition";

const abTransition = createSimpleTransition("ab-transition");
Vue.component("ab-transition", abTransition);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    dark: true,
    options: {
      // customProperties: true,
      variations: false
    },
    themes: {
      dark: {
        // primary: '#1976D2',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
        // primary: "#37BBA9",
        primary: "#37bba9", // "8AAED1", "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        anchor: "#0086FF",
        error: "#F44336",
        info: "#42A5F5",
        success: "#11B59F",
        warning: "#F57C00",
        appBackground: colors.grey.darken3, // Not automatically applied
        background: "#353535" // Not automatically applied
      },
      light: {
        primary: "#1976D2",
        secondary: "#534DBE", //'#424242',
        accent: "#FFA726", // '#82B1FF',
        anchor: "#005BFF",
        error: "#F44336",
        info: "#42A5F5",
        success: "#66BB6A",
        warning: "#F57C00",
        appBackground: colors.indigo.lighten5, // Not automatically applied
        background: "#FFFFFF" // Not automatically applied
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  }
});
