









































































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { RestResponse } from "@/interfaces/RestResponse";
import wretch, { WretcherError } from "wretch";
import { Component, Vue } from "vue-property-decorator";
// Interfaces
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ExSubject } from "@/interfaces/ExSubject";
import { ExBundleGroupVM, ExBundleVM } from '../interfaces/ExBundleGroupVM';
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { QrMediaLinkVM } from "@/interfaces/QrMediaLinkVM";
// Components
import FileDrop from "@/components/FileDrop.vue";
import TusFileDrop from "@/components/TusFileDrop.vue";
import UploadButton from "@/components/UploadButton.vue";
import UploadArchiveMenuButton from "@/components/UploadArchiveMenuButton.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
import { namespace } from "vuex-class";
import { List } from "linq-collections";
// import de from 'vuetify/src/locale/de';

const auth = namespace("auth");

@Component({
  components: {
    FileDrop,
    TusFileDrop,
    UploadButton,
    UploadArchiveMenuButton,
    qrcode: VueQrcode
  }
})
export default class ExerciseManager extends Vue {
  $refs!: {
    detailsPanel: HTMLFormElement;
    qrcodeCanvas: HTMLCanvasElement;
  };
  @auth.Getter isAdmin: any;

  showDeleteFileDialog: boolean = false;
  showDeleteBundleDialog = false;
  showGuidDialog: boolean = false;
  showArchiveLog: boolean = false;
  showMediaLinkDialog: boolean = false;
  grade: number = 1;
  subjects: ExSubject[] = [];
  selectedSubject: ExSubject | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  exBundleGroups: ExBundleGroupVM[] = [];
  selectedBundleGroupPanel: number | null = null;
  selectedBundle: ExBundleVM | null = null;
  fileUploadParam: ParamDictionary = { dictionary: {}};
  fileListParam: ParamDictionary = { dictionary: {}};
  fileDownloadParam: ParamDictionary = { dictionary: {}};
  bundleFileList: string[] | null = null;
  selectedFile: string | null = null;
  newGuid: string | null = null;
  qrMediaLink: QrMediaLinkVM | null = null;
  fileImportList: string[] = [];
  fileImportBusy = false;
  fileUploadList: string[] = [];
  fileUploadBusy = false;
  categoriesArchiveLoading = false;
  bundleZipLoading = false;
  archiveLog = "";

  async mounted() {
    await this.getSubjects();
    this.getGroupedExBundles();
  }

  async getSubjects() {
    this.subjects = await rest.url("exercises/getSubjects").get();
    if (this.selectedSubject != null && this.selectedSubject.id != 0) {
      this.selectedSubject = this.subjects.find(s => s.id == this.selectedSubject?.id ?? 0) ?? null;
    } else {
      this.selectedSubject = this.subjects[0];
    }
  }

  async getGroupedExBundles() {
    let param: ParamGetExercises = {
      grade: this.grade,
      subjectId: this.selectedSubject?.id ?? 0,
      selectedSchoolClassId: 0,
      selectedPupilId: null,
      bundleId: 0
    };
    this.exBundleGroups = await rest.url("contentManager/getGroupedExBundles").post(param);

    if (this.selectedBundle == null)
      return;

    let catName = this.selectedBundle.category;
    if (catName == null) {
      this.selectedBundle = null;
      return;
    }

    let category = this.exBundleGroups.find(g => g.categoryName?.startsWith(catName!));
    if (category) {
      this.selectedBundle = category?.bundles?.find(b => b.guid == this.selectedBundle?.guid) ?? null;
    } else {
      this.selectedBundle = null;
    }
  }

  // Reads Exercise directory on server and imports all bundle information's
  async updateExBundles() {
    await rest.url("contentManager/updateExBundles").post();
    this.getGroupedExBundles();
  }

  async changeGrade(grade: number) {
    this.grade = grade;
    this.getGroupedExBundles();
  }

  async changeSubject(subject: ExSubject) {
    this.selectedSubject = subject;
    this.getGroupedExBundles();
  }

  showExerciseBundle(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.getBundleFiles();
  }

  openDeleteBundleDialog(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.showDeleteBundleDialog = true;
  }

  async deleteBundle() {
    this.fileDownloadParam = { dictionary: {}};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";

    await rest.url("contentManager/removeBundleWithFiles").post(this.fileDownloadParam);
    this.showDeleteBundleDialog = false;
    this.getGroupedExBundles();
  }

  async onBundleOrArchiveFileUploaded(response: any) {
    if (!response.success) {
      this.$store.commit("ux/SB_FAILURE", {
        message: response.message,
        timeout: 0
      });
      return;
    }

    // Add file to list and start import on server
    this.fileImportList.push(response.fileName);
    this.processFileImportList();
  }

  async processFileImportList() {
    if (this.fileImportList.length == 0 || this.fileImportBusy)
      return;

    this.fileImportBusy = true;
    let nextFile = this.fileImportList.pop();
    this.fileUploadParam.dictionary!["FileName"] = nextFile!;
    await rest.url("contentManager/importExerciseExcelOrZipArchiveFile").post(this.fileUploadParam)
    .then(async (response) => {
      if (!response) {
        // await this.loadCategories("update", "");
        await this.getGroupedExBundles();
        return;
      }
      if (nextFile?.endsWith(".xlsx")) {
        let bundle: ExBundleVM = response;
        // Update Subjects
        this.selectedSubject = bundle.exSubject;
        await this.getSubjects();
        // Set grade
        this.grade = bundle.grade;
        await this.getGroupedExBundles();

        let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == bundle.guid));
        if (group) {
          // open expansion panel
          let index = this.exBundleGroups.indexOf(group);
          this.selectedBundleGroupPanel = index;
          // select bundle
          let bundleInGroup = new List(group.bundles!).single(b => b.guid == bundle.guid);
          this.showExerciseBundle(bundleInGroup);
        }
        return;
      }

      this.archiveLog += "<br<br>" + response;
      this.showArchiveLog = true;
      await this.getSubjects();
      await this.getGroupedExBundles();
    })
    .catch(err => {
    })
    .finally(() => {
      this.fileImportBusy = false;
      this.processFileImportList();
    });
  }

  updateView() {
    this.getGroupedExBundles();
    this.getBundleFiles();
  }

  onFileUploadedToTempDir(response: any) {
    if (!response.success) {
      this.$store.commit("ux/SB_FAILURE", {
        message: response.message,
        timeout: 0
      });
      return;
    }

    // Add file to list and start import on server
    this.fileUploadList.push(response.fileName);
    this.processFileUploadList();
  }

  async processFileUploadList() {
    if (this.fileUploadList.length == 0 || this.fileUploadBusy)
      return;

    this.fileUploadBusy = true;
    let nextFile = this.fileUploadList.pop();

    this.fileUploadParam.dictionary!["BundleId"] = this.selectedBundle!.id.toString();
    this.fileUploadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath!; // Shared Resource and Temp dir didn't have a bundle ID
    this.fileUploadParam.dictionary!["FileName"] = nextFile!;
    await rest.url("contentManager/moveFileToLocalDir").post(this.fileUploadParam)
    .then(async (response) => {
      if (!response) {
        return;
      }
      // Select bundle
      let bundle: ExBundleVM = response;
      // Update Subjects
      this.selectedSubject = bundle.exSubject;
      await this.getSubjects();
      // Set grade
      this.grade = bundle.grade;
      await this.getGroupedExBundles();

      let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == bundle.guid));
      if (group) {
        // open expansion panel
        let index = this.exBundleGroups.indexOf(group);
        this.selectedBundleGroupPanel = index;
        // select bundle
        let bundleInGroup = new List(group.bundles!).single(b => b.guid == bundle.guid);
        this.showExerciseBundle(bundleInGroup);
      }
    })
    .catch(err => {
    })
    .finally(() => {
      this.fileUploadBusy = false;
      this.processFileUploadList();
    });

    // this.getGroupedExBundles();
    this.getBundleFiles();
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.newGuid ?? "?");
    this.$store.commit("ux/SB_SUCCESS", {
      message: (this.newGuid ?? "?") + " in Zwischenablage kopiert",
      timeout: 2000
    });
  }

  onReady(canvas) {
    const context = canvas.getContext('2d');
    const image = new Image();

    image.src = 'dc-icon.png';
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const coverage = 0.15;
      const width = Math.round(canvas.width * coverage);
      const x = (canvas.width - width) / 2;

      this.drawImage(context, image, x, x, width, width);
    };
  }

  drawImage(context, image, x, y, width, height, radius = 4) {
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 2;
    context.shadowBlur = 4;
    context.shadowColor = '#00000040';
    context.lineWidth = 8;
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    context.strokeStyle = '#fff';
    context.stroke();
    context.clip();
    context.fillStyle = '#fff';
    context.fillRect(x, x, width, height);
    context.drawImage(image, x, x, width, height);
  }

  async getBundleFiles() {
    if (this.selectedBundle == null)
      return;

    this.bundleFileList = null;
    this.fileListParam.dictionary!["BundleId"] = this.selectedBundle.id.toString();
    this.fileListParam.dictionary!["LocalPath"] = this.selectedBundle.localPath!;
    this.bundleFileList = await rest.url("contentManager/getBundleFiles").post(this.fileListParam);
  }

  get fileCount() {
    if (!this.bundleFileList)
      return "";

    return ` (${this.bundleFileList.length})`
  }

  async downloadFile(fileName: string) {
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = fileName;
    let index = fileName.lastIndexOf("(");
    fileName = fileName.substring(0, index - 1);

    // let token = window.localStorage.getItem("digiClassAuth");
    // wretch("/api/contentManager/downloadFileFromBundle")
    // .auth(`Bearer ${ token }`)
    // .post(this.fileDownloadParam)
    // .blob(data => {
    //   // const blob = new Blob([data], { type: 'application/pdf' });
    //   const blob = new Blob([data]);
    //   const link = document.createElement('a');
    //   link.href = URL.createObjectURL(blob);
    //   link.download = fileName;
    //   link.click();
    //   URL.revokeObjectURL(link.href);
    // })
    // .catch(err => {
    //   let apiError: RestResponse = JSON.parse(err.text);
    //   this.$store.commit("ux/SB_FAILURE", {
    //     message: `Server Error - ${apiError.description}`,
    //     timeout: 0
    //   });
    // });
    this.$globalHelper.download(`api/resource/${this.selectedBundle!.localPath ?? ""}/${fileName}`, fileName);
  }

  async readMediaLink(fileName: string) {
    this.selectedFile = fileName;
    // this.fileDownloadParam.dictionary!["MediaLinkId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = fileName;
    this.fileDownloadParam.dictionary!["YouTubePath"] = this.qrMediaLink?.youTubePath ?? "";

    this.qrMediaLink = await rest.url("contentManager/readMediaLink").post(this.fileDownloadParam);

    this.showMediaLinkDialog = true;
    // Create qrcode logo
    await this.delay(100);
    this.onReady((this.$refs.qrcodeCanvas as any).$el);
  }

  async writeMediaLink() {
    await rest.url("contentManager/writeMediaLink").post(this.qrMediaLink);
    this.showMediaLinkDialog = false;
  }

  async downloadBundleZipFile() {
    this.fileDownloadParam.dictionary = {};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();

    let token = window.localStorage.getItem("digiClassAuth");
    wretch("/api/contentManager/downloadBundleZip")
    .auth(`Bearer ${ token }`)
    .post(this.fileDownloadParam)
    .blob(data => {
      // const blob = new Blob([data], { type: 'application/pdf' });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${ this.selectedBundle!.grade }.${ this.selectedBundle!.subject }-${ this.selectedBundle!.category }-${ this.selectedBundle!.name }.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(err => {
      let apiError: RestResponse = JSON.parse(err.text);
      this.$store.commit("ux/SB_FAILURE", {
        message: `Server Error - ${apiError.description}`,
        timeout: 0
      });
    });
  }

  async createBundleZipFile() {
    this.bundleZipLoading = true;
    this.fileDownloadParam.dictionary = {};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    await rest.url("contentManager/createBundleZipFile")
      .post(this.fileDownloadParam)
      .then((fileName) => {
        this.$globalHelper.download(`api/resource/Temp/${fileName}`, `${ this.selectedBundle!.grade }. Kl. ${ this.selectedBundle!.subject }-${ this.selectedBundle!.category }-${ this.selectedBundle!.name }.zip`);
      })
      .finally(() => {
        this.bundleZipLoading = false;
      })
    // let fileName = `${this.grade}.${this.selectedSubject!.name ?? ""}-all.zip`;
  }

  // async downloadExerciseZipFile() {
  //   this.fileDownloadParam.dictionary = {};
  //   this.fileDownloadParam.dictionary!["Subject"] =  this.selectedSubject!.name ?? "";
  //   this.fileDownloadParam.dictionary!["Grade"] =  this.grade.toString();

  //   let token = window.localStorage.getItem("digiClassAuth");
  //   wretch("/api/contentManager/downloadBundlesForClassAndSubjectZip")
  //   .auth(`Bearer ${ token }`)
  //   .post(this.fileDownloadParam)
  //   .blob(data => {
  //     // const blob = new Blob([data], { type: 'application/pdf' });
  //     const blob = new Blob([data]);

  //     const anchor = document.createElement('a');
  //     anchor.href = URL.createObjectURL(blob);
  //     anchor.download = `${ this.grade }.${ this.selectedSubject!.name }-all.zip`;
  //     anchor.click();
  //     // Remove element from DOM
  //     document.body.removeChild(anchor);
  //     this.$globalHelper.download(URL.createObjectURL(blob), `${ this.grade }.${ this.selectedSubject!.name }-all.zip`);

  //     URL.revokeObjectURL(anchor.href);
  //   })
  //   .catch(err => {
  //     let apiError: RestResponse = JSON.parse(err.text);
  //     this.$store.commit("ux/SB_FAILURE", {
  //       message: `Server Error - ${apiError.description}`,
  //       timeout: 0
  //     });
  //   });
  // }

  async createGradeSubjectArchiveFile() {
    this.categoriesArchiveLoading = true;
    this.fileDownloadParam.dictionary = {};
    this.fileDownloadParam.dictionary!["Grade"] =  this.grade.toString();
    this.fileDownloadParam.dictionary!["Subject"] =  this.selectedSubject!.name ?? "";
    await rest.url("contentManager/createGradeSubjectArchive")
      .post(this.fileDownloadParam)
      .then((fileName) => {
        this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
      })
      .finally(() => {
        this.categoriesArchiveLoading = false;
      })
    // let fileName = `${this.grade}.${this.selectedSubject!.name ?? ""}-all.zip`;
  }

  async deleteFile () {
    this.fileDownloadParam = { dictionary: {}};
    this.fileDownloadParam.dictionary!["BundleId"] =  this.selectedBundle!.id.toString();
    this.fileDownloadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath ?? "";
    this.fileDownloadParam.dictionary!["FileName"] = this.selectedFile ?? "";

    await rest.url("contentManager/deleteFileFromBundle").post(this.fileDownloadParam);
    this.showDeleteFileDialog = false;
    this.getBundleFiles();
  }

  async generateGuid() {
    this.newGuid = await rest.url("contentManager/getNewGuid").get();
    this.showGuidDialog = true;
  }

  // use $vuetify.breakpoint.xs to determine current breakpoint instead of checking height of element
  isDetailsCardVisible(): boolean {
    // const style = getComputedStyle(this.$refs.detailsPanel);
    // let val = style.display !== 'none';
    // https://davidwalsh.name/offsetheight-visibility
    let height = this.$refs.detailsPanel.offsetHeight;
    // alert(height);
    let visible = height !== 0;
    return visible;
  }

  // replace with vuex variant
  get backgroundColor() {
    let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
    return this.$vuetify.theme.themes[currentThemeName].background;
  }

  delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }
}
