
import _ from "lodash";
import rest from "@/rest";
import { List, Enumerable } from 'linq-collections';
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { ExDataSet, ExViewElement, ExChoiceList, ExChoiceListItem, ExChoice, ExQuestion, DcHtmlAttribute } from '../../interfaces/ExDataSet';
import CompBlock from "./CompBlock.vue";
import CompText from "./CompText.vue";
import Linebreak from "./CompLinebreak.vue";
import CompImage from "./CompImage.vue";
import CompHtml from "./CompHtml.vue";
import CompSpeak from "./CompSpeak.vue";
import CompRight from "./CompRight.vue";
import CompWrong from "./CompWrong.vue";
import CompSelect from "./CompSelect.vue";

const globals = namespace("globals");

@Component({
    name: "dchtmlelement",
    components: {
        CompBlock,
        CompText,
        Linebreak,
        CompImage,
        CompHtml,
        CompSpeak,
        CompRight,
        CompWrong,
        CompSelect
    }
})
export default class dchtmlelement extends Vue {
  @Prop() elementName!: string;
  @Prop() attributes!: DcHtmlAttribute[];
  @Prop() compSettings!: string;
  @Prop() alignment!: string;
  @Prop() elements!: ExViewElement[];
  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() size!: string;
  @Prop() color!: string;
  @Prop() weight!: string;
  @Prop() fontStyle!: string;
  @Prop() finalized!: boolean;
  attrsJson = "";

  // $refs!: {};

  mounted() {}

  render (createElement) {
    // console.log("Child Elements of " + this.elementName);
    // this.elements.forEach(e => console.log(e.type));

    let childElements =  new List(this.elements).select(e => createElement(e.type, {
      props: {
        elementName: e.elementName,
        attributes: e.attributes,
        compSettings: e.compSettings,
        text: e.text,
        textArray: e.textArray,
        alignment: e.alignment,
        elements: e.elements,
        src: e.src,
        width: e.width,
        size: e.size,
        color: e.color,
        weight: e.weight,
        height: e.height,
        fontStyle: e.fontStyle,
        preset: e.preset,
        choiceList: e.choiceList,
        finalized: this.finalized,
        // @changed: onChoiceSelection // TODO: How to handle events with createElement function???
        // https://vuejs.org/v2/guide/render-function.html#:~:text=Render%20Functions%20%26%20JSX.%20Basics.%20Vue%20recommends%20using,the%20render%20function%2C%20a%20closer-to-the-compiler%20alternative%20to%20templates.
      }
    })).toArray();

    // console.log("Created elements");
    // console.log(childElements);

    // return createElement(this.elementName, this.hyperText, childElements);
    // return createElement(this.elementName, { attrs: { "color": "green" } }, this.hyperText, childElements);
    // return createElement(this.elementName, { attrs: this.htmlAttributes, on: { changedCompSelect: this.onChoiceSelection } }, [ this.hyperText, childElements ]);
    return createElement(this.elementName, { attrs: this.htmlAttributes }, [ this.hyperText, childElements ]);
  }

  // After using vuex as to store choice state, this emit can be removed
  // async onChoiceSelection(id: number, isRight: boolean) {
  //     console.log("Yehhh :)");
  //     this.$emit("changed:CompSelect", id, isRight);
  // }

  get htmlAttributes(): any {
    if (!this.attributes)
      return {};

    let firstAttribute = true;
    this.attrsJson = "{ ";
    this.attributes.forEach(a => {
      if (!firstAttribute) {
        this.attrsJson += ", ";
      }
      firstAttribute = false;
      this.attrsJson += `\"${a.name}\": \"${a.value}\"`;
    });
    this.attrsJson += " }";

    return JSON.parse(this.attrsJson);
  }

  // get fontSize() {
  //     if (this.size == null)
  //         return "24px";
  //     return this.size;
  // }

  get hyperText() {
    if (this.text != null)
      return this.text;
    if (this.textArray != null)
      return this.textArray.join('');

    return null;
  }
}
