












































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import PupilQrCodeCard from "@/components/SchoolClasses/PupilQrCodeCard.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode


const globals = namespace("globals");

@Component({
  components: {
    PupilQrCodeCard,
    qrcode: VueQrcode
  }
})
export default class PrintPupilsQrCodes extends Vue {
  @Prop() visible!: boolean;

  @globals.Getter selectedClass!: SchoolClassVM;

  localShowView: boolean = false;
  // hideFromPrint = false;
  pupils: PortalUserVM[] = [];

  async mounted() {
  }

  @Watch("visible")
  async onVisibilityChanged(val: boolean) {
    // just used as a trigger to toggle dialog visibility
    this.localShowView = !this.localShowView;

    // On dialog open
    if (this.localShowView) {
        this.updatePupilList();
    }
  }

  async updatePupilList() {
    if (this.selectedClass == null)
      return;

    this.pupils = await rest.url("teacher/getPupils").query({ schoolClassId: this.selectedClass.id }, true).get();
  }

  async onPrintQrCodes() {
    // await this.delay(100);
    window.print();
    // await this.delay(100);
    // this.hideFromPrint = false;
  }

  onClose() {
    this.$store.commit('globals/setViewToPrintMode', false);
    this.localShowView = false;
  }

  delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }
}
