import Vue from "vue";
import Router from "vue-router";
import { VueInstance } from "./main";
import Store from "./store";
import LandingPage from "./views/LandingPage.vue";
import Freebies from "./views/Freebies.vue";
import GdprApproval from "./views/GdprApproval.vue";
import Gdpr from "./views/Gdpr.vue";
import Imprint from "./views/Imprint.vue";
import TermsOfUse from "./views/TermsOfUse.vue";
import Home from "./views/Home.vue";
import Admin from "./views/Admin.vue";
import ManageNotifications from "./views/ManageNotifications.vue";
import SchoolsView from "./views/SchoolsView.vue";
import SchoolClassesView from "./views/SchoolClassesView.vue";
import PupilsView from "./views/PupilsView.vue";
import ExercisesView from "./views/ExercisesView.vue";
import ClassExerciseOverview from "./views/ClassExerciseOverview.vue";
import ExerciseBundleView from "./views/ExerciseBundleView.vue";
// import ExerciseCatalog from "./views/ExerciseCatalog.vue";
import Exercise from "./views/Exercise.vue";
import ExerciseManager from "./views/ExerciseManager.vue";
import ExerciseAssignment from "./views/ExerciseAssignment.vue";
import PupilPerformanceOverview from "./views/PupilPerformanceOverview.vue";
import ClassPerformanceOverview from "./views/ClassPerformanceOverview.vue";
import About from "./views/About.vue";
import ContentCreationHelp from "./views/ContentCreationHelp.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "landingPage",
      component: LandingPage,
    },
    {
      path: "/freebies",
      name: "freebies",
      component: Freebies,
    },
    {
      path: "/gdprApproval",
      name: "gdprApproval",
      component: GdprApproval,
    },
    {
      path: "/gdpr",
      name: "Gdpr",
      component: Gdpr,
    },
    {
      path: "/imprint",
      name: "Imprint",
      component: Imprint,
    },
    {
      path: "/termsOfUse",
      name: "TermsOfUse",
      component: TermsOfUse,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"] ||
          VueInstance.$store.getters["auth/isNotificationAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"] ||
          VueInstance.$store.getters["auth/isLearner"] ||
          VueInstance.$store.getters["auth/isParent"] ||
          VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"]) return next();
        router.push("/");
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: ManageNotifications,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isNotificationAdmin"]
        ) return next();
        router.push("/");
      },
    },    {
      path: "/schools",
      name: "schools",
      component: SchoolsView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/schoolClasses",
      name: "schoolClasses",
      component: SchoolClassesView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/pupils",
      name: "pupils",
      component: PupilsView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exercises",
      name: "exercises",
      component: ExercisesView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"] ||
          VueInstance.$store.getters["auth/isLearner"] ||
          VueInstance.$store.getters["auth/isParent"] ||
          VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/classExerciseOverview",
      name: "classExerciseOverview",
      component: ClassExerciseOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exerciseBundle",
      name: "exerciseBundle",
      component: ExerciseBundleView,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"] ||
          VueInstance.$store.getters["auth/isLearner"] ||
          VueInstance.$store.getters["auth/isParent"] ||
          VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exerciseAssignment",
      name: "exerciseAssignment",
      component: ExerciseAssignment,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exercise",
      name: "exercise",
      component: Exercise,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"] ||
          VueInstance.$store.getters["auth/isLearner"] ||
          VueInstance.$store.getters["auth/isParent"] ||
          VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/pupilPerformanceOverview",
      name: "pupilPerformanceOverview",
      component: PupilPerformanceOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
          // || VueInstance.$store.getters["auth/isParent"]
          // || VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/classPerformanceOverview",
      name: "classPerformanceOverview",
      component: ClassPerformanceOverview,
      beforeEnter: (to, from, next) => {
        if (
          VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isPrincipal"] ||
          VueInstance.$store.getters["auth/isTeacher"]
          // || VueInstance.$store.getters["auth/isParent"]
          // || VueInstance.$store.getters["auth/isPupil"]
        )
          return next();
        router.push("/");
      },
    },
    {
      path: "/exerciseManager",
      name: "exerciseManager",
      component: ExerciseManager,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/contentCreationHelp",
      name: "contentCreationHelp",
      component: ContentCreationHelp,
      beforeEnter: (to, from, next) => {
        if (VueInstance.$store.getters["auth/isAdmin"] ||
          VueInstance.$store.getters["auth/isContentManager"]
        ) return next();
        router.push("/");
      },
    },
    {
      path: "/about",
      name: "about",
      component: About,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
  ],
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

// */
// Navigation Guards
// in order of lifecycle
// */

router.beforeResolve((to, from, next) => {
  if (to.name) {
    VueInstance.$wait.start("router");
  }
  next();
});

router.beforeEach((to, from, next) => {
  function proceed() {
    // console.log("Store.state: " + Store.state.ready);
    // console.log("from: " + from.name);
    // console.log("to: " + to.name);
    if (to.name != "gdprApproval" && Store.state.ready && (VueInstance?.$store.state.auth.portalUser?.gdprApprovalRequired ?? false))
      router.push("/gdprApproval");
    if (Store.state.ready && from.name == "home" && to.name != "home") {
      VueInstance.$store.dispatch("notify/topicNotificationsNoted");
    }

    if (Store.state.ready) next();
  }
  if (!Store.state.ready) {
    Store.watch(
      (state: any) => state.ready,
      (value: any) => {
        if (value === true) {
          proceed();
        }
      }
    );
  }
  proceed();
});

router.afterEach((to, from) => {
  VueInstance.$wait.end("router");
});

export default router;
