



































































































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import SetAvatar from "@/components/SetAvatar.vue"; // @ is an alias to /src
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { ParamLoadPortalUsers } from "@/interfaces/ParamLoadPortalUsers";
import { PortalUserTableDataVM } from "@/interfaces/PortalUserTableDataVM";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component({
  components: {
    SetAvatar
  }
})
export default class UserManager extends Vue {
  showEditUserDialog = false;
  showNewUserDialog = false;
  showDeleteUserDialog = false;
  users: PortalUserVM[] | null = [];
  defaultUser: PortalUserVM = {
    id: "0",
    firstName: "",
    lastName: "",
    nickname: "",
    fullName: "",
    userName: "",
    email: "",
    disabled: false,
    selectRoles: [] as SelectListItem[],
    roles: [],
    showMenu: false,
    selected: false,
    avatar: "",
    disableAvatar: false,
    gdprApprovalDate: null,
    gdprApprovalRequired: false,
    activationKey: "",
    schoolId: 0,
    schoolClassId: 0,
    schools: [] as SchoolVM[],
    classLeaderOf: [] as SchoolClassVM[],
    schoolClasses: [] as SchoolClassVM[]
  };

  // selectedUser: PortalUserVM = Object.assign({}, this.defaultUser);
  selectedUser: PortalUserVM = _.cloneDeep(this.defaultUser);
  selectedUserIdx: number = -1;
  newUserFormValid: boolean = true;

  $refs!: {
    addNewUserForm: HTMLFormElement;
  };

  // users table
  userSearch: string = "";
  tableOptions: any =  {};
  tableTotalCnt: number = 0;
  tableLoading: boolean = false;
  userTableHeaders = [
    {
      text: "",
      value: "avatar",
      sortable: false
    },
    {
      text: "Name",
      value: 'fullName',
      align: 'start',
      sortable: true,
    },
    { text: "Benutzername", value: "userName" },
    { text: "Email", value: "email" },
    { text: "DSGVO Zustimmung", value: "gdprApprovalDate",  },
    { text: "Akt. Key", value: "activationKey", sortable: false },
    { text: "Aktionen", value: "action", width: "110px", sortable: false }
  ];

  // headers = [
  //   {
  //     text: "",
  //     value: "avatar",
  //     sortable: false
  //   },
  //   {
  //     text: "Name",
  //     align: "left",
  //     value: "fullName",
  //     sortable: true
  //   },
  //   { text: "Benutzername", value: "userName" },
  //   { text: "Email", value: "email" },
  //   { text: "Akt. Key", value: "activationKey" },
  //   { text: "Aktionen", value: "action", sortable: false }
  // ];

  mounted() {
    this.updateUserList();
  }

  @Watch("showNewUserDialog")
  onShowDialogChanged(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async updateUserList() {
    this.tableLoading = true;
    let loadParams: ParamLoadPortalUsers = <ParamLoadPortalUsers>{ dtOptions: this.tableOptions, search: this.userSearch };
    let data: PortalUserTableDataVM = await rest.url("admin/loadPortalUsers").post(loadParams).finally(() => this.tableLoading = false);
    this.users = data.portalUsers;
    this.tableTotalCnt = data.total;
    // console.log("users:\r\n" + this.users);
  }

  @Watch("tableOptions")
  onOptionsChanged() {
    this.updateUserList();
  }

  onSearchChanged() {
    this.updateUserList();
  }

  editItem(user: PortalUserVM) {
    this.selectedUserIdx = this.users?.indexOf(user) ?? 0;
    this.selectedUser = Object.assign({}, user);
    this.showEditUserDialog = true;
  }

  onAvatarChanged(avatar: string) {
    this.selectedUser.avatar = avatar;
  }

  onShowDeleteUserDialog(user: PortalUserVM) {
    this.selectedUser = user;
    this.showDeleteUserDialog = true;
  }

  async onDeleteUser() {
    await rest.url("admin/deleteUser").post(this.selectedUser);
    let index = (this.users as PortalUserVM[]).indexOf(this.selectedUser);
    (this.users as PortalUserVM[]).splice(index, 1);
    this.showDeleteUserDialog = false;
  }

  closeEditDialog() {
    this.showEditUserDialog = false;
    setTimeout(() => {
      // this.selectedUser = Object.assign({}, this.defaultUser);
      this.selectedUser = _.cloneDeep(this.defaultUser);
      this.selectedUserIdx = -1;
    }, 300);
  }

  async saveEditDialog() {
    if (this.users == null)
      return;

    if (this.selectedUserIdx > -1) {
      Object.assign(this.users[this.selectedUserIdx], this.selectedUser);
      if (this.selectedUser.lastName != null) {
        this.users[this.selectedUserIdx].fullName = this.selectedUser.lastName;
      }
      if (
        this.selectedUser.lastName != null &&
        this.selectedUser.firstName != null
      ) {
        this.users[this.selectedUserIdx].fullName += ", ";
      }
      if (this.selectedUser.firstName != null) {
        this.users[
          this.selectedUserIdx
        ].fullName += this.selectedUser.firstName;
      }

      await rest.url("admin/saveUser").post(this.selectedUser);
    }
    //  else {
    //   this.users.push(this.selectedUser);
    // }
    this.closeEditDialog();
  }

  openNewUserDialog() {
    // this.selectedUser = Object.assign({}, this.defaultUser);
    this.selectedUser = _.cloneDeep(this.defaultUser);
    this.showNewUserDialog = true;
  }

  createNewKey() {
    this.selectedUser.activationKey = "";
    for (let i = 0; i <= 7; i++) {
      this.selectedUser.activationKey += Math.floor(Math.random() * 10);
    }
  }

  closeNewDialog() {
    this.showNewUserDialog = false;

    setTimeout(() => {
      // this.selectedUser = Object.assign({}, this.defaultUser);
      this.selectedUser = _.cloneDeep(this.defaultUser);
      this.selectedUserIdx = -1;
    }, 300);
  }

  async saveNewDialog() {
    if (!this.$refs.addNewUserForm.validate()) return;

    await rest.url("admin/addUser").post(this.selectedUser);
    this.updateUserList();
    this.closeNewDialog();
  }

  // openRolesMenu(event: MouseEvent, user: PortalUserVM) {
  openRolesMenu(user: PortalUserVM) {
    if (this.users == null)
      return;

    this.selectedUserIdx = this.users.indexOf(user);
    this.selectedUser = _.cloneDeep(user);
    user.showMenu = true;
  }

  closeRolesMenu(user: PortalUserVM) {
    user.showMenu = false;
  }

  async saveRoles(user: PortalUserVM) {
    await rest.url("admin/setUserRoles").post(this.selectedUser);
    this.$set(this.users as PortalUserVM[], this.selectedUserIdx, this.selectedUser);
    user.showMenu = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Validation

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  mailEditRules = [
    (v: string) =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length > 5) || "Benutzername zu kurz."
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz."
  ];

  keyEditRules = [
    (v: string) =>
      !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz."
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];
}
