

































































































































// import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';
import FileDrop from "@/components/FileDrop.vue";

// Interfaces
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { PupilVM } from "@/interfaces/PupilVM";
// import { PortalUser, SchoolClass, School } from "@/interfaces/PortalUser";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ParamImportPupils } from "@/interfaces/ParamImportPupils";

// import { VRowExpandTransition } from "vuetify/lib"; // ???
import de from 'vuetify/src/locale/de';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const globals = namespace("globals");

@Component({
  components: {
    FileDrop
  }
})
export default class ImportPupils extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @PropSync('showDialog', { type: Boolean }) syncedShowDialog!: boolean;
  csvImportMode = false;
  pupils: PupilVM[] = [];
  filteredPupils: PupilVM[] = [];
  paramDir: ParamDictionary = { dictionary: {}};
  classNames: string[] = [];
  filterClass: string | null = null;
  searchName: string | null = null;
  busy = false;

  // $refs!: { };

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      this.csvImportMode = false;
      this.searchName = null;
      this.loadPupilsImportList();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }

  async loadPupilsImportList() {
    if (this.paramDir == null || this.paramDir.dictionary == null)
      return;
    this.pupils = [];
    this.filteredPupils = [];

    this.paramDir.dictionary["Grade"] = this.selectedClass.grade.toString();
    this.paramDir.dictionary["SchoolId"] = this.selectedClass.schoolId.toString();
    this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString();
    this.pupils = await rest.url("teacher/loadPupilsImportList").post(this.paramDir);
    this.setSchoolClasses();
  }

  setSchoolClasses() {
    this.classNames = new List(this.pupils).where(p => p.className != null).select(p => p.className ?? "" ).distinct().toArray();
    this.filterClass = null;
    this.onFilterChanged();
  }

  onSelectClass() {
    if (this.filterClass == null)
      return;
    new List(this.pupils).where(p => p.className == this.filterClass).forEach(p => p.selected = true);
    this.onFilterChanged();
  }

  onDeselectAll() {
    new List(this.pupils).forEach(p => p.selected = false);
    this.onFilterChanged();
  }

  onSearchNameClear() {
    this.searchName = null;
    this.onFilterChanged();
  }

  onFilterChanged() {
    this.filteredPupils = this.pupils;

    if (this.filterClass != null)
      this.filteredPupils = new List(this.filteredPupils).where(p => p.className == this.filterClass).toArray();

    if (this.searchName != null) {
      let verbs = this.searchName.toLowerCase().trim().split(" ");
      if (verbs.length >= 2) {
        let firstVerb  = verbs[0];
        let secondVerb = verbs[1];
        this.filteredPupils = new List(this.filteredPupils)
          .where(p =>
            ((p.firstName?.toLowerCase()?.indexOf(firstVerb) ?? -1) >= 0 && (p.lastName?.toLowerCase()?.indexOf(secondVerb) ?? -1) >= 0) ||
            ((p.firstName?.toLowerCase()?.indexOf(secondVerb) ?? -1) >= 0 && (p.lastName?.toLowerCase()?.indexOf(firstVerb) ?? -1) >= 0)
          ).toArray();
      } else {
        let search = this.searchName.toLowerCase().trim();
        this.filteredPupils = new List(this.filteredPupils)
          .where(p => (p.firstName?.toLowerCase()?.indexOf(search) ?? -1) >= 0 || (p.lastName?.toLowerCase()?.indexOf(search) ?? -1) >= 0).toArray();
      }
    }
  }

  async importPupils() {
    this.busy = true;
    let pupils = new List(this.pupils).where(p => p.selected).toArray();
    let params = <ParamImportPupils> { schoolClassId: this.selectedClass.id, pupils: pupils};
    await rest.url("teacher/importPupils").post(params).finally(() => this.busy = false);
    this.$emit('pupilsImported:ImportPupils');
    this.closeDialog();
  }

  get selectionCount() {
    return new List(this.pupils).count(p => p.selected);
  }

  get disableImport() {
    if (this.csvImportMode) {
      return true;
    } else {
      if (this.busy || this.selectionCount == 0 || this.selectionCount > 40)
        return true;
      else
        return false;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
