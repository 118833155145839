

















































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";


const globals = namespace("globals");

@Component({
    components: {}
})
export default class ExBundleStatus extends Vue {
    @Prop() bundleAssignment!: ExBundleAssignmentVM;
    @Prop() isTeacher!: boolean;

    // $refs!: {};

    mounted() {}
}
