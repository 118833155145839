































































































































import _, { sortBy } from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { List, Enumerable } from 'linq-collections';
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
// import { ExSubject } from "@/interfaces/ExSubject";
// import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM, ExBundleVM } from '../interfaces/ExBundleAssignmentGroupVM';
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ResultOverviewVM } from "@/interfaces/ResultOverviewVM";
import { ExCategory } from '@/interfaces/PortalUser';
import { KeyValue } from '@/interfaces/KeyValue';
import PerformanceListItemStatus from "@/components/PerformanceOverview/PerformanceListItemStatus.vue";
// import { ExTagAchievementVM } from '@/interfaces/ExTagAchievementVM';
// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");

@Component({
  components: {
    PerformanceListItemStatus
  }
})
export default class ClassPerformanceOverview extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  // @globals.Getter selectedSubject!: ExSubject;
  // @globals.Getter selectedPupil!: PortalUserVM;
  // sortAscending: boolean = true;
  // showFilterMenu:boolean= false;
  // subjects: ExSubject[] = [];
  // localSubject: ExSubject | null = null;
  // categories: ExCategory[] = [];
  // selectedCategory: ExCategory | null = null;
  paramDir: ParamDictionary = { dictionary: {}};
  resultOverviews: ResultOverviewVM[] | null = null;
  resultOverviewSorted: ResultOverviewVM[] | null = null;
  sortByAlphabet: boolean = false;
  sortOrderAscending: boolean = false;


  breadcrumbItems = [
      { text: 'Start', disabled: false, href: '/home' },
      { text: 'Meine Klassen', disabled: false, href: '/exercises' },
      { text: 'Klassenleistungsstand', disabled: true, href: '/classPerformanceOverview' },
  ];

//   timeFrames: KeyValue[] = [
//     { key: 'alles', value: 'all' },
//     { key: '1 Woche', value: 'oneWeek' },
//     { key: '1 Monat', value: 'oneMonth' },
//     { key: '1. Halbjahr', value: 'firstHalfOfYear' },
//     { key: '2. Halbjahr', value: 'secondHalfOfYear' }
//   ];
//   selectedTimeFrame: KeyValue  = this.timeFrames[2];

  async mounted() {
      if (this.selectedClass == null) {
          router.push("/");
          return;
      }

    //   await this.getSubjects();
    //   await this.getCategories();
    await this.getClassResults();
    //   await this.getTagAchievement();
      // this.getAssignmentsForAllExBundles();
  }

//   trainingColor(achivement: ExTagAchievementVM) {
//     if (achivement.notWellTrained)
//       return "grey";

//     return "light-blue";
//   }

  async getClassResults() {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id!.toString();

    this.resultOverviews = await rest.url("teacher/classPerformanceOverview").post(this.paramDir);
    // console.log(this.resultsOverview);
    this.onSort();
  }

  onPupilPerformanceOverview(pupil: PortalUserVM) {
    if (pupil == null)
      return;

    this.$store.commit('globals/setPupil', pupil);
    router.push("/pupilPerformanceOverview");
  }

  onSort() {
    if (this.resultOverviews == null)
      return;

    if (!this.sortByAlphabet && this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderBy(r => r.trophyValue).thenBy(r => r.cumulatedExerciseProgress).thenBy(r => r.starCountValue).toArray();
    if (!this.sortByAlphabet && !this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderByDescending(r => r.trophyValue).thenByDescending(r => r.cumulatedExerciseProgress).thenByDescending(r => r.starCountValue).toArray();
    if (this.sortByAlphabet && !this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderByDescending(r => r.pupil?.fullName).toArray();
    if (this.sortByAlphabet && this.sortOrderAscending)
      this.resultOverviewSorted = new List(this.resultOverviews).orderBy(r => r.pupil?.fullName).toArray();
  }

//   async getTagAchievement() {
//      if (this.paramDir == null || this.paramDir.dictionary == null)
//       return;

//     this.paramDir.dictionary["sortAscending"] = this.sortAscending.toString()!;
//     this.paramDir.dictionary["PupilId"] = this.selectedPupil.id!;
//     this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString()!;
//     this.paramDir.dictionary["SubjectId"] = (this.selectedSubject?.id ?? 0).toString();
//     this.paramDir.dictionary["CategoryId"] = (this.selectedCategory?.id ?? 0).toString();
//     this.paramDir.dictionary["timeFrame"] = this.selectedTimeFrame.value!;

//     this.tagAchievements = await rest.url("teacher/loadTagAchievementList").post(this.paramDir);
//     // console.log(this.resultsOverview);
//   }

//   async getSubjects() {
//     this.subjects = await rest.url("exercises/getSubjects").get();
//     if (this.selectedSubject != null && this.selectedSubject.id != 0) {
//       let subject = this.subjects.find(s => s.id == this.selectedSubject.id);
//       this.$store.commit("globals/setSubject", subject);
//     } else {
//       this.$store.commit("globals/setSubject", this.subjects[0]);
//     }
//     this.localSubject = this.selectedSubject;
//   }

//   async changeSubject() {
//       // console.log("subject changed" + this.localSubject)
//       this.$store.commit("globals/setSubject", this.localSubject);
//       await this.getCategories();
//       this.getTagAchievement();
//   }

//   async getCategories() {
//      if (this.paramDir == null || this.paramDir.dictionary == null)
//       return;

//     this.paramDir.dictionary["PupilId"] = this.selectedPupil.id!;
//     this.paramDir.dictionary["SchoolClassId"] = this.selectedClass.id.toString()!;
//     this.paramDir.dictionary["SubjectId"] = this.selectedSubject.id.toString()!;

//     this.categories = await rest.url("exercises/loadCategories").post(this.paramDir);
//     this.selectedCategory = this.categories[0];
//   }

//   async changeCategory() {
//       this.getTagAchievement();
//   }

  goBack() {
    router.go(-1);
  }
}
