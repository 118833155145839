































































































































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import moment, { duration } from "moment";
import { ExBundleAssignmentGroupVM, ExBundleAssignmentVM } from '../../interfaces/ExBundleAssignmentGroupVM';
import { PortalUserVM, SelectListItem, SchoolVM, SchoolClassVM } from "@/interfaces/PortalUserVM";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ParamGetExercises } from "@/interfaces/ParamGetExercises";
import { List } from "linq-collections";

//const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class AssignmentMenu extends Vue {
  @globals.Getter selectedClass!: SchoolClassVM;
  @globals.Getter selectedPupil!: PortalUserVM;
  // @globals.Getter pupilsOfSelectedClass!: PortalUserVM[];

  // @Prop() showMenu!: boolean;
  @Prop() assignment!: ExBundleAssignmentVM;
  busyOnSave = false;
  busyOnDelete = false;
  assignmentDateDlg: boolean = false;
  deadlineDateDlg: boolean = false;
  deadlineTimeDlg: boolean = false;
  localShowMenu: boolean = false;
  showPupilSelectionMenu = false;
  assignedToPupils = 0;
  editAssignment: ExBundleAssignmentVM | null = null;
  assignmentDate: string | null = null;
  deadlineDate: string | null = null;
  deadlineTime: string | null = null;
  pupilAssignments: ExBundleAssignmentVM[] = [];

  // @auth.Getter isAdmin: any;
  // @auth.Getter isPrincipal: any;
  // @auth.Getter isTeacher: any;
  // @auth.Getter isParent: any;
  // @auth.Getter isPupil: any;

  mounted() {
    // console.log("AssignmentMenu mounted");
    // Make sure that the showDialog variable of the parent is in sync upon initialisation
    // this.$emit('update:showMenu', false);
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async openMenu(on) {
    //console.log("AssignmentMenu openMenu");
    //this.$emit('update:showMenu', true);
    this.pupilAssignments = [];
    this.localShowMenu = on;
    if (this.assignment.isAssignedToIndividualPupils && !this.assignment.isAssignedToClass)
      this.assignedToPupils = 1;
    else
      this.assignedToPupils = 0;

    this.editAssignment = _.cloneDeep(this.assignment);
    // AssignmentDate
    this.assignmentDate = moment(this.editAssignment.assignmentDate).format("yyyy-MM-DD");
    // console.log("assignmentDate: " + this.assignmentDate);
    // Deadline
    // console.log("deadline:" + this.editAssignment.deadline);
    this.deadlineDate = moment(this.editAssignment.deadline).format("yyyy-MM-DD");
    // console.log("deadlineDate: " + this.deadlineDate);
    this.deadlineTime = moment(this.editAssignment.deadline).format("HH:mm"); // HH => 24h format
    // console.log("deadlineTime: " + this.deadlineTime);

    // Load assignments to individual pupils
    await this.loadPupilList();
    if (this.assignment.isAssignedToIndividualPupils && !this.assignment.isAssignedToClass) {
      // await this.loadPupilList();
      this.applyPupilsList();
    }

    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.delay(10);
      const modal = document.querySelector('.modal');
      disableBodyScroll(modal);
    });
  }

  async loadPupilList() {
    let param: ParamGetExercises = {
      bundleId: this.assignment.exBundle?.id ?? 0,
      selectedSchoolClassId: this.selectedClass.id,
      grade: 0, // Not relevant
      subjectId: 0, // Not relevant
      selectedPupilId: null,// Not relevant
    };
    this.pupilAssignments = await rest.url("teacher/getPupilAsignmentsToBundle").post(param);
  }

  onClassSelected() {
    if (!this.editAssignment)
      return;

    this.editAssignment.isAssignedToClass = true;
    this.editAssignment.isAssignedToIndividualPupils = false;
    // Restore original values??
  }

  async onSelectPupils() {
    if (!this.editAssignment)
      return;

    // this.loadPupilList();
    this.editAssignment.isAssignedToClass = false;
    this.editAssignment.isAssignedToIndividualPupils = true;

    clearAllBodyScrollLocks();
    this.showPupilSelectionMenu = true; // At this moment the modal class will be removed from base dialog
    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.delay(10);
      const modal = document.querySelector('.modal'); // This time we will set the scroll lock to the pupil selection dialog.
      disableBodyScroll(modal);
    });
  }

  selectAll() {
    new List(this.pupilAssignments).forEach(a => a.isAssignedToIndividualPupils = true);
  }

  clearAll() {
    new List(this.pupilAssignments).forEach(a => a.isAssignedToIndividualPupils = false);
  }

  applyPupilsList() {
    // If active assignment is available, preset assignmentDate and deadlineDate
    if (this.editAssignment && new List(this.pupilAssignments).firstOrDefault(a => a.isAssignedToIndividualPupils)) {
      let activeAssignment = new List(this.pupilAssignments).firstOrDefault(a => a.isAssignedToIndividualPupils);
      this.assignmentDate = moment(activeAssignment!.assignmentDate).format("yyyy-MM-DD");
      this.editAssignment.hasDeadline = activeAssignment!.hasDeadline;
      this.deadlineDate = moment(activeAssignment!.deadline).format("yyyy-MM-DD");
    } else {
      // No active assignment available
      this.assignmentDate = moment(new Date()).format("yyyy-MM-DD");
      this.deadlineDate = moment(new Date()).add(duration(2, "days")).format("yyyy-MM-DD");
      this.deadlineTime = moment(new Date("2000-01-01T20:00")).format("HH:mm"); // HH => 24h format
    }

    //clearAllBodyScrollLocks();
    this.showPupilSelectionMenu = false;
  }

  async updateAssignment(isAssigned: boolean) {
      if (this.editAssignment == null)
        return;

      this.busyOnSave = isAssigned;
      this.busyOnDelete = !isAssigned;

      // Deadline
      let deadlineDateTime = `${this.deadlineDate}T${this.deadlineTime}`;
      let assignmentDate = moment(this.assignmentDate).toDate();
      let deadline = moment(deadlineDateTime).toDate();

      if (this.editAssignment.isAssignedToIndividualPupils) {
        // new List(this.pupilAssignments).forEach(a => a.isAssignedToIndividualPupils = isAssigned);
        new List(this.pupilAssignments).forEach(a => a.assignmentDate = assignmentDate);
        new List(this.pupilAssignments).forEach(a => a.hasDeadline = this.editAssignment!.hasDeadline);
        new List(this.pupilAssignments).forEach(a => a.deadline = deadline);
        let updatedAssignment = await rest.url("teacher/updatePupilAssignments").post(this.pupilAssignments);
      } else {
        this.editAssignment.isAssignedToClass = isAssigned;
        this.editAssignment.assignmentDate = assignmentDate;
        this.editAssignment.deadline = deadline;
        let updatedAssignment = await rest.url("teacher/updateClassAssignment").post(this.editAssignment);
      }

      this.$emit("updateView:AssignmentMenu");
      clearAllBodyScrollLocks();
      this.busyOnSave = false;
      this.busyOnDelete = false;
      this.localShowMenu = false;
  }

  adjustDaedlineAndCloseMenu() {
    if (moment(this.assignmentDate).isAfter(moment(this.deadlineDate))) {
      this.deadlineDate = this.assignmentDate;
    }
    this.assignmentDateDlg = false;
  }

  get pupilButtonLable() {
    if (!this.editAssignment?.isAssignedToIndividualPupils || this.pupilAssignments.length == 0)
      return "";
    if (!this.pupilAssignments)
      return "";
    return `${new List(this.pupilAssignments).count(a => a.isAssignedToIndividualPupils)} / ${new List(this.pupilAssignments).count()}`;
  }

  get minDate() {
    //console.log("Now: " + this.$moment().year());
    return new Date().toISOString().substr(0, 10);
  }

  get assignmentDateFormated() {
    return this.formatCalendar(this.assignmentDate);
  }

  get deadlineDateFormated() {
    return this.formatDate(this.deadlineDate);
  }

  get baseDialogModal () {
    if (!this.showPupilSelectionMenu)
      return "modal";
    return "";
  }

  get pupilSelectionDialogModal () {
    if (this.showPupilSelectionMenu)
      return "modal";
    return "";
  }

  formatDate(date) {
    if (date == null)
      return "-";

    return moment(date).format("DD.MM.yyyy");
  }

  formatCalendar(date) {
    if (date == null)
      return "-";

    let mDate = moment(date);
    if (mDate.date() == moment().date())
      return ("sofort");

    //return this.formatDate(this.editAssignment?.assignmentDate);
    //return  this.$moment(this.editAssignment.assignmentDate).format("dddd DD.MM.YYYY");
    return mDate.calendar();
  }

  // beforeDestroy() {
  //   debugger
  //   clearAllBodyScrollLocks();
  //   this.$emit('update:showDialog', false);
  // }

  // @Watch("showMenu")
  // onShowMenuChanged(val: boolean) {
  //   console.log("AssignmentMenu onShowMenuChanged");
  //   if (val == true) {
  //     // Dialog opened
  //     this.editAssignment = _.cloneDeep(this.assignment);
  //     // const modal = document.querySelector('.modal');
  //     // disableBodyScroll(modal);
  //     // document.getElementsByTagName("body")[0].className="noscroll";
  //     this.localShowMenu = true;
  //   } else {
  //     // Dialog closed
  //     // clearAllBodyScrollLocks();
  //     // document.body.removeAttribute("class","noscroll");
  //     this.localShowMenu = false;
  //   }
  // }

  onCancel() {
    //this.$emit('update:showMenu', false);
    clearAllBodyScrollLocks();
    this.localShowMenu = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
